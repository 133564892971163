
// Mixins
// --------------------------------------------------

@mixin reset {
  padding: 0;
  margin: 0;
}

@mixin reset-list {
  @include reset;
  list-style: none;
  list-style-type: none;
  list-style-image: none;
}

@mixin bp($point, $limiter: min) {
  // $point:    xs, sm, md, lg, xl
  // $limiter:  min (default), max, only

  @if $point == retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
           only screen and (min--moz-device-pixel-ratio: 2),
           only screen and (min-device-pixel-ratio: 2) { @content; }
  }
  @else {

    @if $limiter == min {
      @if $point == xs {
        @media only screen and (min-width: $xs-width) { @content; }
      }
      @else if $point == sm {
        @media only screen and (min-width: $sm-width) { @content; }
      }
      @else if $point == md {
        @media only screen and (min-width: $md-width) { @content; }
      }
      @else if $point == lg {
        @media only screen and (min-width: $lg-width) { @content; }
      }
      @else if $point == xl {
        @media only screen and (min-width: $xl-width) { @content; }
      }
    }

    @if $limiter == max {
      @if $point == xs {
        @media only screen and (max-width: $sm-width - 0.01) { @content; }
      }
      @else if $point == sm {
        @media only screen and (max-width: $md-width - 0.01) { @content; }
      }
      @else if $point == md {
        @media only screen and (max-width: $lg-width - 0.01) { @content; }
      }
      @else if $point == lg {
        @media only screen and (max-width: $xl-width - 0.01) { @content; }
      }
      @else if $point == xl {
        @content;
      }
    }

    @if $limiter == only {
      @if $point == xs {
        @media only screen and (min-width: $xs-width) and (max-width: $sm-width - 0.01) { @content; }
      }
      @else if $point == sm {
        @media only screen and (min-width: $sm-width) and (max-width: $md-width - 0.01) { @content; }
      }
      @else if $point == md {
        @media only screen and (min-width: $md-width) and (max-width: $lg-width - 0.01) { @content; }
      }
      @else if $point == lg {
        @media only screen and (min-width: $lg-width) and (max-width: $xl-width - 0.01) { @content; }
      }
      @else if $point == xl {
        @media only screen and (min-width: $xl-width) { @content; }
      }
      @else {
        @content;
      }
    }

  }
}

// UNIT CONVERSION
// Convert rems to pixels etc.

// Strip any unit from numeric variables
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Pixel to rem
@function ptr($num, $showUnit: false) {
  @if ($showUnit) {
    @return #{strip-unit($num) / 16}rem;
  }
  @return strip-unit($num) / 16;
}

// Rem to pixels
@function rtp($num, $showUnit: false) {
  @if ($showUnit) {
    @return #{strip-unit($num) * 16}px;
  }
  @return strip-unit($num) * 16;
}

// FONT SIZE
// Calculate rem value from px size

// FONT SIZING
// Adds a default line-height for a font-size.
@mixin font-size($values...) {
  $max: length($values);
  $font-size: 16 !default;
  $line-height: false !default;

  // If font-size is supplied, override default
  @if $max != 0 {
    $font-size: nth($values, 1);
  }
  // If line-height is supplied, override default
  @if $max > 1 and type-of(nth($values, 2)) == number {
    $line-height: nth($values, 2);
  }
  // Else calculate line-height using $font-size
  @else if $line-height == false {
    $line-height: $font-size * $line-height-factor;
  }
  // Output supplied values
  @if $max != 0 {
    @if nth($values, $max) == "!important" {
      font-size: $font-size / 16 + rem !important;
      @if $line-height != 0 {
        line-height: $line-height / 16 + rem !important;
      }
    }
    @else {
      font-size: $font-size / 16 + rem;
      @if $line-height != 0 {
        line-height: $line-height / 16 + rem;
      }
    }
  }
  // Else output default values
  @else {
    font-size: $font-size / 16 + rem;
    line-height: $line-height / 16 + rem;
  }
}

// FONT SMOOTHING
// Better text rendering for modern browsers

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// APPEARANCE
// https://github.com/postcss/autoprefixer#why-doesnt-autoprefixer-support-appearance
@mixin appearance($style) {
  -moz-appearance: $style;
  -webkit-appearance: $style;
  appearance: $style;
}

// ICONS
// Add icon font character to element

@mixin icon($icon, $position: "before", $replace: false, $breakpoint: false) {
  @if $replace {
    font-size: 0;
  }
  &:#{$position} {
    @extend .icon-#{$icon}:before !optional;
    font-family: $icon-font;
    speak: none;
    @include font-size($icon-font-size, 0);
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    @if $replace and $breakpoint == false {
      font-size: 1rem;
    } @else if $breakpoint {
      font-size: 0;
    }
    @content;
  }
  @if $breakpoint {
    @include breakpoint($breakpoint){
      &:#{$position} {
        @include font-size($default-font-size, 0);
      }
    }
  }
}

@mixin hide-icon-text($font-size: $default-font-size, $color: $default-text-color) {
  font-size: 1px;
  line-height: 1px;
  letter-spacing: -1px;
  color: transparent;
  &:before,
  &:after {
    letter-spacing: normal;
    @include font-size($font-size, 0);
    color: $color;
    @content;
  }
}


// GRID
// ...

@mixin container() {
  position: relative;
  margin: auto;
  padding-left: $gutter;
  padding-right: $gutter;
  max-width: $max-site-width;
}

@mixin row() {
  @extend .clearfix;
  margin-left: -$gutter/2;
  margin-right: -$gutter/2;
}

@mixin col() {
  position: relative;
  float: left;
  padding-left: $gutter/2;
  padding-right: $gutter/2;
  width: 100%;
}

@mixin cols($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    width: percentage($cols / $grid-columns);
  } @else {
    width: 100%;
  }
}
@mixin cols-offset($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    margin-left: 100% / ($grid-columns/$cols);
  }
}
@mixin cols-push($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    left: 100% / ($grid-columns/$cols);
  }
}
@mixin cols-pull($values...) {
  $max: length($values);
  @if ($max != 0) {
    $cols: nth($values, 1);
    right: 100% / ($grid-columns/$cols);
  }
}

// Clearfix
// ...
@mixin clearfix() {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}