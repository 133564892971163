// Region: Toolbar
// --------------------------------------------------

.region--toolbar {
  padding: 0.3rem 0;
  background-color: $color-background-dark;
  color: $color-grey;

  .vlaanderen-link {
    @include font-size(13, 0);
    @include bp(sm) {
      @include font-size(18, 0);
    }
    @include bp(md) {
      @include font-size(20, 0);
    }
    color: $color-grey;
    font-family: $secondary-font-medium;
    margin-top: 1rem;
    margin-right: 1rem;
    display: block;
    float: left;
  }

  .mobile-vlaanderen-logo {
    float: left;
    height: 35px;
    margin-top: 10px;
    @include bp(sm) {
      height: 50px;
    }
  }

}