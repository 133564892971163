// Region: Content
// --------------------------------------------------

.region--content {
  padding-top: 4rem;
  @include bp(md) {
    padding-top: 22rem;
  }

  .container {
    @extend .clearfix;
    min-height: 55rem;
    background-color: $color-white;
    padding-top: 2rem;
  }
}
