// Forms
//--------------------------

// Form item

.form-item,
fieldset {
  margin-bottom: 2.5rem;
}

// Required

.form-required {
  color: $color-error;
}

// Disabled

.form-disabled {
  opacity: .5;

  input {
    cursor: not-allowed;

    &:active,
    &:focus,
    &:hover {
      border-color: $color-border;
      box-shadow: none;
    }
  }
}

// Description

.form-item .description {
  margin-top: 0.5rem;
  @include font-size(12);
}

// Labels

.form-item {
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.form-item.label-inline,
.webform-container-inline {
  @include bp(sm) {
    letter-spacing: -0.25rem; // Remove the space between inline elements
    > * {
      letter-spacing: 0px;
    }
    label {
      display: inline-block !important;
      margin-top: 0.5rem;
      margin-bottom: 0;
      width: 12rem;
      letter-spacing: 0.2em;
    }
    input {
      vertical-align: top;
    }
    .description {
      margin-left: 12rem;
    }
    .form-textarea-wrapper {
      display: inline-block;
    }
    &.form-type-textarea label {
      vertical-align: top;
    }
  }
  .description {
    font-weight: 400;
    letter-spacing: normal;
  }
}

.compact-form-wrapper {
  position: relative;
}

.compact-form-label {
  position: absolute;
  color: $color-muted;
  cursor: text;
  font-weight: 400;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: $color-muted;
  opacity: 1;
}

// Text fields and text areas

input,
textarea {
  width: 27rem;
  max-width: 100%;
  border: $form-border;
  border-radius: $form-border-radius;
  padding: $form-padding;
  @include appearance(none);
  font-weight: 400;

  &:hover {
    outline: none;
    border-color: $color-form-hover;
    box-shadow: 0 0 2px 1px rgba($color-form-shadow, 0.15);
  }
  &:active,
  &:focus {
    outline: none;
    border-color: $color-form-focus;
    box-shadow: 0 0 2px 1px rgba($color-form-focus, 0.25);
  }
  &.error {
    border: 1px solid $color-error;
    box-shadow: 0 0 2px 1px rgba($color-error, 0.25);
  }
}

input {
  @include font-size($form-font-size, 0);
  line-height: normal;
  transition: border-color $default-transition;
}

textarea {
  width: 100%;
  @include font-size($form-font-size, 22);
  transition: border-color $default-transition;
}

// max character counter
.form-item {
  .counter {
    @extend .description;
    padding: 0.5rem 1rem;
    text-align: right;
    strong {
      color: $color-black;
    }
    &.error {
      color: $color-text;
    }
  }
}

// Grippie for textareas

.grippie {
  display: none;
}

// File field

input[type="file"] {
  line-height: 1;
  vertical-align: top;
  border-radius: $form-border-radius 0 0 $form-border-radius;
  border-width: 1px 0 1px 1px;
  @include font-size(11, 0); // Best cross-browser results

  + button {
    padding: 0.78rem 0.85rem;
    border-radius: 0 $form-border-radius $form-border-radius 0;
    line-height: 1;
    @include font-size(12, 0); // line-height needs to be unit-less.
  }
}

// Image field

input[type="image"] {
  vertical-align: middle;
}

// Radio & Checkbox

.form-type-radios,
.form-type-checkboxes {
  .form-item {
    margin-bottom: 0.5rem;
  }
}

.form-type-radio,
.form-type-checkbox {
  input {
    @extend .element-invisible;
    &:focus + label:before,
    &:hover + label:before {
      border: solid 1px $color-link-hover;
      transition: border-color $default-transition;
    }
  }
  label {
    margin: 0;
    position: relative;
    display: inline-block;
    padding-left: 1.5rem;
    @include font-size($form-font-size, 0);
    font-weight: 400;
    letter-spacing: normal;
    &:before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 0.1rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      border: solid 1px $color-form-border;
    }
  }
}

.form-type-radio {
  label:before {
    border-radius: 50%;
  }
  input:checked + label:before {
    background: $color-link url(../images/sprite.png) -1px -1px no-repeat;
    border-color: $color-link;
    background-size: auto;
    html.svg & {
      background: $color-link url(../images/sprite.svg) -1px -1px no-repeat;
    }
  }
}

.form-type-checkbox {
  label:before {
    border-radius: 0.125rem;
  }
  input:checked + label:before {
    background: $color-link url(../images/sprite.png) -1px -17px no-repeat;
    background-size: auto;
    border-color: $color-link;
    html.svg & {
      background: $color-link url(../images/sprite.svg) -1px -17px no-repeat;
      border-color: $color-link;
    }
  }

}

// Select

.form-type-select {
  label {
    display: block;
  }
}

.form-select-wrapper {
  border: $form-border;
//  border-radius: $form-border-radius;
//  background: $color-white url(../images/sprite.png) 87.5% -40px no-repeat;
  background-size: auto;
  display: inline-block;
  min-width: 8rem;
  max-width: 100%;

  &:hover {
    outline: none;
//    border-color: $color-form-hover;
//    box-shadow: 0 0 2px 1px rgba($color-form-shadow, 0.15);
    transition: border-color $default-transition;
  }
  &:active,
  &:focus {
    outline: none;
//    border-color: $color-error;
//    box-shadow: 0 0 2px 1px rgba($color-form-focus, 0.25);
  }
  &.error {
    border: 1px solid $color-error;
    box-shadow: 0 0 2px 1px rgba($color-error, 0.25);
  }
  html.svg & {
    background: $color-white url(../images/sprite.svg) 87.5% -40px no-repeat;
  }
  html.ie & {
    background-image: none;
  }
  select {
    width: 100%;
    border: 0 none;
    border-radius: 0px;
    background: transparent;
    @include font-size($form-font-size, 0);
    line-height: normal;
    padding: $form-padding 2rem $form-padding $form-padding;
    @include appearance(none);
    &:active,
    &:focus {
      outline: 0;
    }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $color-black;
    }
    &::-ms-expand {
      display: none;
    }
    html.ie & {
      padding: 0.9375rem 1rem;
    }
  }
}

// Date

.form-type-date {
  .form-item {
    display: inline;
  }
  input[type="image"] {
    background: transparent;
    border: none;
  }
}

// Time

.form-type-webform-time {
  .form-type-select {
    display: inline;
  }
}

// Fieldsets

fieldset {
  padding: 1rem;
  border: $form-border;
  border-radius: $form-border-radius;
  .fieldset-wrapper {
    clear: both;
  }
  html.js &.collapsible {
    .fieldset-legend {
      padding-left: 0;
      background: none;
      @include icon('arrow-up') {
        color: $color-black;
        margin-right: 0.25rem;
      }
    }
    .fieldset-wrapper {
      display: block;
    }
  }
  html.js &.collapsible.collapsed {
    .fieldset-legend {
      @include icon('arrow-down');
    }
    .fieldset-wrapper {
      display: none;
    }
  }
}

.fieldset-no-border {
  padding: 0;
  border: 0 none;
  > legend {
    @extend h3;
  }
}

// Clearable fields

.clearable-action {
  color: $color-white;
  background: $color-link;
  position: absolute;
  right: $form-padding;
  top: $form-padding;
  cursor: pointer;
  display: none;
  width: 1rem;
  height: 1rem;
  @include font-size(12, rtp(1));
  text-align: center;
  border-radius: 2px;
}

.clearable-wrapper {
  position: relative;
  display: inline-block;

  input {
    padding-right: 2rem;
  }
}

// Autocomplete

#autocomplete,
ul.better-autocomplete {
  border: 1px solid $color-border;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

#autocomplete ul,
ul.better-autocomplete {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

#autocomplete li,
ul.better-autocomplete li.result {
  padding: 0.2rem $form-padding !important;
  background: $color-white;
  color: $color-text;
  cursor: default;
  white-space: pre;
  zoom: 1; /* IE7 */
  line-height: 1.2;
  // border-bottom: $default-border;
  // &:last-child {
  //   border-bottom: 0 none;
  // }
  &:hover {
    background-color: $color-link;
    cursor: pointer;
  }
}

ul.better-autocomplete {
  background: $color-white;
  li {
    border-bottom: $default-border;
    h3,
    h4,
    p {
      margin: 0;
      @include font-size(14);
    }
  }
  li.group {
    padding: 0.2rem $form-padding !important;
    background: $color-grey;
  }
  li.result {

  }
}

// Actions

.form-actions {
  button {
    margin-right: 1rem;
  }
}

// tabledrag

.tabledrag-toggle-weight-wrapper {
  display: none;
}

table.tabledrag-processed {
  th, td {
    padding: 0.5rem 0.5rem 0.5rem 0;
    border: 0 none;
  }
}

.draggable {
  line-height: 2.5rem;
  .handle {
    float: left;
    cursor: move;
    @include icon('handle');
  }
}

td.field-multiple-drag {
  width: 2.5rem;
}

.tabledrag-changed {
  display: none;
}

// multiple files 

.field-type-file {
  fieldset {
    margin-bottom: 0;
  }
  table {
    width: 32.5rem;
    max-width: 100%;
    thead {
      display: none;
    }
    tr.draggable {
      line-height: 1;
    }
    tr td:last-child {
      width: 4rem;
    }
    .file-widget {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include font-size(14);
    }
    .form-submit {
      @extend .button-small;
    }
  }
}

// datepicker

.ui-datepicker {
  background: $color-white;
  border: $default-border;
  .ui-datepicker-header {
    position: relative;
    padding: 8px;
    .ui-datepicker-title {
      width: 100%;
      text-align: center;
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
      position: absolute;
      top: 8px;
      @extend .button;
      @extend .button-small;
    }
    .ui-datepicker-prev {
      left: 8px;
    }
    .ui-datepicker-next {
      right: 8px;
    }
  }
  table.ui-datepicker-calendar {
    margin: 0;
    padding: 0;
    td {
      margin: 0;
      padding: 0;
      text-align: center;
      a {
        display: inline-block;
        padding: 8px;
      }
    }
  }
  .ui-datepicker-current-day {
    font-weight: 800;
  }
  .ui-state-active {
    background: $color-primary;
  }
}

// Chosen
//--------------------------

// reset
.chosen-container-single .chosen-drop,
.chosen-container.chosen-with-drop .chosen-drop,
.chosen-container-single .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-single {
  background: $color-white;
  box-shadow: none;
  border: none;
}

.chosen-container-multi .chosen-choices {
  border: 0 none;
}

.chosen-container {
  width: 100% !important;
  @include font-size($form-font-size, 0);
}

.chosen-container-single .chosen-single {
  width: 100%;
  height: auto;
  padding: 0.4rem $form-padding;
  font-weight: 400;
  background: none;
  > div {
    display: none;
  }
}

.chosen-container-multi .chosen-choices {
  background: none;
  li.search-field input[type="text"] {
    width: 100%;
    height: auto;
    padding: $form-padding;
    @include appearance(none);
    font-weight: 400;
  }
}

.chosen-container-single .chosen-drop,
.chosen-container.chosen-with-drop .chosen-drop {
  border: $form-border;
  border-bottom-right-radius: $form-border-radius;
  border-bottom-left-radius: $form-border-radius;
}

.chosen-container-single .chosen-search {
  padding: 0;
  input[type="text"] {
    border: none;
    border-bottom: $form-border;
  }
}

// dropdown with results
.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
  li {
    color: $color-text;
  }
  li.highlighted {
    color: $color-text;
    background: $color-link;
  }
}

// selected results in input field
.chosen-container-multi .chosen-choices li.search-choice {
  margin: 7px 0 7px 7px;
  background: $color-grey;
  border: $form-border;
  box-shadow: none;
  color: $color-text;
}

.chosen-container .search-choice-close {
  transition: none;
}
