// Document
// --------------------------------------------------

// Box Sizing

*,
*:before,
*:after {
  box-sizing: border-box;
}

// Prevent scrollbars

html, body {
  overflow-x: hidden; // necessary for extend classes
}

html {
  -webkit-text-size-adjust: none; // Prevent font scaling in landscape
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
}

body {
  overflow: hidden;
}

// Document settings

body {
  font-family: $secondary-font, $font-fallback;
  font-weight: 400;
  line-height: 1.6;
  color: $color-text;
  background-color: $color-white;
  @include font-smoothing(antialiased);
  @include font-size(14);
  @include bp(md) {
    @include font-size(16);
  }
}

// Document background image styling.
.main-bg-pic, .footer-bg-pic {
  position: absolute;
  z-index: -999;
  overflow: hidden;
  display: block;
  max-width: none;
  max-width: initial;
}

.main-bg-pic {
  display: none;
  @include bp(md) {
    display: block;
  }
  top: 3rem;
  height: 700px;
  z-index: -500;
}

.footer-bg-pic {
  width: initial;
  @include bp(lg) {
    width: 100%;
  }
  bottom: 160px;
}

// Document selection styling.
*::selection {
  background-color: $color-secondary;
  color: $color-white;
}

*::-moz-selection {
  background-color: $color-secondary;
}

*::-webkit-selection {
  background-color: $color-secondary;
}

img::selection {
  background-color: transparent;
}

img::-moz-selection {
  background-color: transparent;
}

img::-webkit-selection {
  background-color: transparent;
}

// Skip link styling
#skip-link {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 50;
  background: #444;
  background: rgba(0, 0, 0, 0.6);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.94em;
  line-height: 1.7em;
  padding: 1px 10px 2px;
  border-bottom-width: 0;
  outline: 0;
}

#skip-link.visually-hidden.focusable:focus {
  position: absolute !important;
  color: #fff !important;
}

div#main-content:focus {
  outline: none;
}