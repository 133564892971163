
// Social media
// --------------------------------------------------

.block--social-media {
	ul.menu {
		li {
			float: left;
			a {
				color: $color-muted;
				&:hover {
					color: $color-primary;
				}	
			}			
		}
	}
}

// Addthis

.at3lblight {
	@extend .overlay-background;
}

.addthis_toolbox {
	a {
		display: block !important;
		float: left !important;
		padding: 0 !important;
		margin-right: 0.2rem !important;
		background: $color-black !important;
		width: 2rem !important;
		height: 2rem !important;
		line-height: 2rem !important;
		&:before {
			color: $color-white !important;
			width: 2rem !important;
			text-align: center !important;
		}
		&:hover:before {
			color: $color-primary !important;
		}
		> span {
			display: none !important;
		}
	}
	a.addthis_button_facebook {
		@include icon('social-facebook');
	}
	a.addthis_button_twitter {
		@include icon('social-twitter');	
	}
	a.addthis_button_google_plusone_share {
		@include icon('social-google');	
	}
	a.addthis_button_reddit {
		@include icon('social-reddit');	
	}
	a.addthis_button_addthis {
		@include icon('plus') {
			color: $color-text !important;
		}
		background: $color-primary !important;
	}
}

// Jump to Facebook Comments

.pane--jump-to-facebook-comments {
	@include bp(md) {
		display: none;
	}
	text-align: center;
	a.link-to-fb-comments {
		color: $color-muted;
		&:hover {
			color: $color-link;
		}
	}
}

// Facebook Comments

.pane--facebook-comments {
	@include bp(md) {
		.pane-content {
			width: (100%/12) * 7;
			margin-left: (100%/12) * 1;
		}
	}
}
.fb-comments {
	margin-left: -6px;
	margin-right: -6px;
	width: 100% !important;
	width: calc(100% + 12px) !important;
	> span, iframe {
		width: 100% !important;
	}
}
