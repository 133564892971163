
// Menu
//--------------------------

ul.menu {
	li {
		margin-bottom: 0.5rem;
	}
	a {
		color: $color-text;
	  font-weight: 400;
	  &:hover {
	  	color: $color-link;
	  }
	}
	.active-trail a,
	a.active-trail,
	.active a,
	a.active {
		color: $color-primary;
		font-weight: 800;
	}
}