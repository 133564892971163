
// User
// --------------------------------------------------

.user-profile {
	position: relative;
}

// Fields
// --------------------------------------------------

// View modes
// --------------------------------------------------

// List

.user-profile.view-mode-list {

}

// Full

.user-profile.view-mode-full {

}