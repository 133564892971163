// Views
// --------------------------------------------------

// Header

.view-header {
  @extend p.lead !optional;
  margin-bottom: $default-block-margin-bottom;
}

// Footer

.view .more-link {
  a {
    @extend .read-more;
  }
}

// Content
.view-content {
  @extend .clearfix;
}

// Empty view

.view-empty {
  @extend .messages;
  p {
    margin-bottom: 0;
  }
}

// Exposed filters
// --------------------------------------------------

.views-exposed-widget {
  float: left;
}

.view-filters {
  //  @extend .col;

  // reset
  form,
  .form-item {
    margin: 0;
    padding: 0;
  }
  .views-exposed-form {
    border: $default-border;
    background: $color-grey;
    margin-bottom: $default-block-margin-bottom;
  }
  // hide
  .views-submit-button,
  .description {
    // display: none;
  }
  //
  .form-submit {
    margin: 0;
  }
  // inline labels
  .views-exposed-widget {
    float: left;
    padding: 0.5rem 1rem;
    border-right: $default-border;
    label {
      float: left;
      display: inline-block;
      width: auto;
      margin-right: 0.5rem;
    }
    .form-text,
    .views-widget,
    .form-select-wrapper {
      display: inline-block;
      max-width: 12rem;
    }
  }
  // sorting etc. floats right
  .views-exposed-options {
    float: right;
    border-left: $default-border;
    .views-exposed-widget:last-child {
      border-right: 0 none;
    }
  }
}

// views exposed filters sidebar category links
// .natura2000-exposed-filter-panel
.views-exposed-widgets {

  .views-exposed-widget.views-widget-filter-field_categorie_soort_tid {
    width: 100%;
  }

  .form-select-wrapper {
    width: 100%;
    border: none;
    background: none !important;
    border-radius: 0;

    position: relative;
    padding: 0rem 1rem 0 1rem;
    border: 2px solid $color-primary;

    @include bp(md) {
      &:before {
        content: " ";
        position: absolute;
        height: 110%;
        width: 2px;
        left: 0;

        background: rgba(255, 255, 255, 0);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(20%, rgba(10, 113, 140, 1)), color-stop(80%, rgba(10, 113, 140, 1)), color-stop(100%, rgba(10, 113, 140, 0)));
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80 rgba(10, 113, 140, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#0a718c', GradientType=0);
      }

      .form-item.form-type-bef-link a {
        padding: 0.5rem 0 0.5rem 1rem;
        margin-right: 0;
      }
    }

    #toggle-filters {
      display: block;
      @include bp(md) {
        display: none;
      }
      position: absolute;
      padding: 1rem;
      right: 0%;
      top: 0;
      z-index: 20;
      &:hover {
        cursor: pointer;
      }

      @include icon('arrow-down');
      &:before {
        @include font-size(18, 0);
      }

    }

    .bef-select-as-links.open {
      #toggle-filters {
        @include icon('arrow-up');
      }
      .form-item.form-type-bef-link a {
        display: block !important;
      }
    }

    .form-item.form-type-bef-link {
      a {
        display: none;

        &.active {
          display: block;
          font-weight: 600;
          &:before {
            color: $color-white;
            border-color: $color-primary;
          }
        }

        padding: 1rem 0 1rem 2rem;
        margin-right: 2rem;

        // Bullets per item
        @include icon('bullet') {
          @include font-size(10, 0);
          color: $color-primary;
          font-weight: 900;
          font-stretch: expanded;
          border: 4px solid $color-white;
          border-radius: 50%;
          position: absolute;
          left: 1rem
        }
        &:hover:before {
          color: $color-white;
          border-color: $color-primary;
          // The (nice, smooth) transition causes the effect to lag & stay on elements for no reason, so removed for now.
          //      transition: color $default-transition, background-color $default-transition, border $default-transition;
        }

      }

    }

    @include bp(md) {
      padding: 0;
      border: none;

      &:before {
        content: "";
      }

      .bef-select-as-links {
        padding-top: 2rem;
      }

      .form-item.form-type-bef-link {
        a {
          display: block;
          padding: 1rem 0 1rem 1rem;
          &:before {
            left: -8px;
          }
        }
      }

    }

  }

  .form-item {
    margin-bottom: 0;
  }

  .description {
    display: none;
  }

}

// Infinite scroll
// --------------------------------------------------

@keyframes infinite-scroll-loop {
  0% {
    color: $color-grey;
  }
  50% {
    color: $color-black;
  }
  100% {
    color: $color-grey;
  }
}

#views_infinite_scroll-ajax-loader {
  text-align: center;
  @include icon('infinite-scroll') {
    @include font-size(36);
    animation: infinite-scroll-loop 0.5s $easeInOut infinite;
  }
  img {
    display: none;
  }
}

//// Load more
ul.pager.pager-load-more {
  // AJAX throbber for views lode more event.
  .ajax-progress {
    position: absolute;
    top: 1.7rem;
    right: -2rem;
  }
}

// Grids
// --------------------------------------------------

// Teaser grid

.view-teaser-grid .view,
.view.view-teaser-grid {
  @extend .row;
}

.view-teaser-grid {
  .views-row {
    @extend .col;
    @extend .col-md-6;
    @extend .col-lg-3;
    margin-bottom: $gutter;
  }
}

// Teaser grid with Large Teaser at top

.view-teaser-large-teaser-grid .view,
.view.view-teaser-large-teaser-grid {
  @extend .row;
}

.view-teaser-large-teaser-grid {
  .views-row:first-child {
    @extend .col;
  }
  .views-row:not(:first-child) {
    @extend .col;
    @extend .col-md-6;
    @extend .col-lg-3;
    margin-bottom: $gutter;
  }
}

// Soorten & habitat overzichten layout.
.view-soorten,
.view-habitattypes {

  .views-row {
    @extend .col;
    @extend .col-lg-4;
    @extend .col-xs-6;
  }

  &.view-display-id-habitattype_gebieden,
  &.view-display-id-habitattype_inspanningen,
  &.view-display-id-habitattype_natuurdoelen,
  &.view-display-id-species_in_flanders_info,
  &.view-display-id-efforts_connected_to_species,
  &.view-display-id-areas_connected_to_species,
  &.view-display-id-goals_connected_to_species {
    .views-row {
      @extend .col;
      @extend .col-xs-12;
    }
  }

}

// Style list of related things.
.view-display-id-habitattype_gebieden,
.view-display-id-habitattype_natuurdoelen,
.view-display-id-habitattype_inspanningen,
.view-display-id-gebied_projecten,
.view-display-id-gebied_publicaties,
.view-display-id-gebied_soorten,
.view-display-id-gebied_habitattypes,
.view-display-id-efforts_connected_to_species,
.view-display-id-goals_connected_to_species,
.view-display-id-areas_connected_to_species,
.view-display-id-gebied_inspanningen,
.view-display-id-gebied_doelstellingen {
	.views-row {
  		margin-bottom: 0.6rem;
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.views-widget-filter-field_categorie_project_tid {
  width: 100%;
}