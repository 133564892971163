// Projecten
// --------------------------------------------------

.view-projecten {

  .views-row .node {

//    @extend .col;
//    @extend .col-md-9;
//    @extend .col-lg-8;
//    @extend .col-md-offset-3;
    padding: 1rem;

  }

}

// Projecten detail
.node-type-project {

  .pane-node-field-id-gebied,
  .pane-node-field-id-prioritaire-inspanning,
  .pane-node-field-id-van-habitattypes,
  .pane-node-field-id-van-soorten {
    h2 {
      @include font-size(20);
      font-family: $secondary-font;
      font-weight: 400;
    }
  }

  .pane-node-field-id-gebied {
    .node-gebied.node-teaser {
      margin-bottom: 0;
      min-height: 3rem;
    }
    .field--title {
      position: absolute;
      z-index: 10;
      text-align: center;
      display: block;
      width: 100%;
      color: $color-white;
      padding: 0.5rem 0;
      background-color: $color-secondary;
      bottom: 1rem;
      h2 {
        margin: 0;
      }
    }
  }

}
