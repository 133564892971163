// Tabs
// --------------------------------------------------

//
// Primary tabs
//

ul.tabs.primary {
  @extend .clearfix;
  list-style: none;
  border-bottom: $default-border;
  li {
    position: relative;
    display: block;
    float: left;
    margin-bottom: -2px;
    margin-right: 2rem;
    .ajax-progress {
      position: absolute;
      top: 0.5rem;
      right: -1rem;
    }
  }
  a {
    display: block;
    padding: 0.5rem 0;
    font-weight: 400;
    border-bottom: solid 3px transparent;
    color: $color-black;
    &:before {
      @include font-size(12, 0);
    }
  }
  a:hover {
    border-color: $color-link;
  }
  li.active a {
    font-weight: 700;
    border-color: $color-primary;
  }
}

//
// Vertical tabs, with responsive integration.
//

.pane-natura-custom-blocks-natura-area-subpage-switcher {

  .area-navigation {
    left: -5px;

    #toggle-tabs {
      display: block;
      @include bp(md) {
        display: none;
      }
      position: absolute;
      padding: 1rem;
      right: 0%;
      top: 0;
      z-index: 20;
      &:hover {
        cursor: pointer;
      }

      @include icon('arrow-down');
      &:before {
        @include font-size(18, 0);
      }

    }

    &.open {
      #toggle-tabs {
        @include icon('arrow-up');
      }
      .area-navigation-link-wrapper {
        display: block !important;
      }
    }

    position: relative;
    padding: 0 1rem;
    border: 2px solid $color-primary;

    @include bp(md) {
      &:before {
        content: " ";
        position: absolute;
        height: 115%;
        width: 2px;
        left: 0;

        background: rgba(255, 255, 255, 0);
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(20%, rgba(10, 113, 140, 1)), color-stop(80%, rgba(10, 113, 140, 1)), color-stop(100%, rgba(10, 113, 140, 0)));
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
        background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 20%, rgba(10, 113, 140, 1) 80 rgba(10, 113, 140, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#0a718c', GradientType=0);
      }

      .area-navigation-link-wrapper a {
        padding: 0.5rem 0 0.5rem 1rem;
        margin-right: 0;
      }
    }

    .area-navigation-link-wrapper {
      display: none;

      &.active {
        display: block;
      }

      &.active a {
        * {
          font-weight: 600;
        }
        &:before {
          color: $color-white;
          border-color: $color-primary;
        }
      }

      a {
        * {
          font-weight: 200;
        }
        display: block;
        padding: 1rem 0 1rem 2rem;
        margin-right: 2rem;

        // Bullets per item
        @include icon('bullet') {
          @include font-size(10, 0);
          color: $color-primary;
          font-weight: 900;
          font-stretch: expanded;
          border: 4px solid $color-white;
          border-radius: 50%;
          position: absolute;
          left: 1rem
        }
        &:hover:before {
          color: $color-white;
          border-color: $color-primary;
          // The (nice, smooth) transition causes the effect to lag & stay on elements for no reason, so removed for now.
          //      transition: color $default-transition, background-color $default-transition, border $default-transition;
        }

      }

    }

    @include bp(md) {
      padding: 0;
      border: none;

      &:before {
        content: "";
      }

      .area-navigation-link-wrapper {
        display: block;

        &:first-child {
          padding-top: 2rem;
        }
        a {
          padding: 1rem 0 1rem 1rem;
          &:before {
            left: -8px;
          }
        }
      }

    }

  }

}