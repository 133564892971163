
// Breadcrumb
// ---------------------------------------------------------------------

ul.breadcrumb {
  display: none;
  @include bp(md) {
    display: block;
  }

  padding: 0;
  list-style: none;
  overflow: hidden;

  li {
    float: left;
    margin: 0 0.5rem 0 0;
    @include font-size(16, 0);

    @include icon('arrow-right-bold', after) {
      color: $color-text;
      @include font-size(10, 0);
      padding-left: 0.5rem;
      vertical-align: middle;
    }

    a {
      text-decoration: none;
      font-family: $secondary-font-medium;
      color: $color-text
    }
    a, span {
      position: relative;
      display: block;
      float: left;
    }
    &.last {
      &:after {
        content: "";
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
}
