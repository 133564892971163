// Navigation
// --------------------------------------------------

// Main navigation
.main-menu {

  float: left;
  width: 100%;

  ul.menu {
    @extend .clearfix;
    z-index: 1000;
    padding-bottom: 1rem;

    li.leaf {
      @include reset-list;

      float: left;
      display: block;
      width: 100%;

      a {
        @include font-size(22, 0);
        font-weight: 700;

        display: block;
        font-family: $secondary-font;
        color: $color-white;
        padding: 2rem 1rem;
        margin-right: 1rem;

        &:hover {
          background-color: $color-primary-muted;
          text-decoration: none;
        }

        &.active {
          background-color: $color-primary-muted;
        }

      }

    }

  }

  @include bp(lg) {
    width: auto;

    ul.menu {
      padding: 0;

      li.leaf {
        float: left;
        width: auto;

        a {
          @include font-size(20, 0);
          font-weight: 400;
          padding: 2rem 1rem;
          margin-right: 0rem;
        }

      }

    }

  }

}

// Toolbar navigation
.region--toolbar {
  .block-menu {
    display: inline-block;
    float: right;
  }

  ul.menu {
    float: right;

    li.leaf {
      display: none;

      &.mobile-show {
        display: block;
      }

      @include bp(sm) {
        &.tablet-show {
          display: block;
        }
      }

      @include bp(md) {
        display: block;
      }

      @include reset-list;
      float: left;

      a {
        @include font-size(16, 0);

        display: block;
        font-family: $secondary-font;
        color: $color-grey;
        padding: 1rem 0.5rem;

        &:hover {
          color: $color-white;
          text-decoration: none;
        }

        &.active {
          text-decoration: none;
          color: $color-white;
        }

      }

    }

  }
}

// Mobile menu
.main-menu {
  display: none;

  &.open {
    display: block;
  }

  @include bp(lg) {
    display: block;
  }
}

.toggle-search,
.toggle-menu {

  .text {
    display: none;
  }

  display: block;
  float: right;
  color: $color-white;
  padding: 1rem;
  @include bp(sm) {
    margin-top: 0.5rem;
  }
  @include bp(lg) {
    margin-top: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: $color-white;
  }

  &.close {
    // Display a cross icon when search/menu is open, so it can be closed.
    @include icon('close') {
      @include font-size(24, 0);
    }
  }

}

.toggle-search {
  @include icon('search') {
    @include font-size(24, 0);
  }
}

.toggle-menu {
  @include icon('handle') {
    @include font-size(24, 0);
  }
  @include bp(lg) {
    display: none;
  }
}

.menu-footer-block {
  ul.menu {
    column-count: 3;
    li {
      a:hover {
        color: $color-white;
        text-decoration: underline;
      }
      &.active-trail a {
        color: $color-white;
        text-decoration: underline;
      }

    }
  }

}

// Nested menu (about natura pages)
.over-ons-side-menu {
  border: 1px solid $color-primary;
  padding: 0.6rem 2rem;
  @include bp(md) {
    border: none;
    padding: 0.6rem 1rem;
    border-left: 4px solid $color-primary;
  }

  #toggle-menu {
    display: block;
    @include bp(md) {
      display: none;
    }
    position: absolute;
    padding: 1rem;
    right: 0%;
    top: 0;
    z-index: 20;
    &:hover {
      cursor: pointer;
    }

    @include icon('arrow-down');
    &:before {
      @include font-size(18, 0);
    }
  }

  @include bp(sm, max) {
    & ul.menu:first-child {
      li {
        display: none !important;
      }
      li.active-trail {
        display: block !important;
        a {
          margin-top: 0.6rem !important;
        }
      }
    }
    &.select-first ul.menu:first-child > li.first:first-child,
    &.select-first ul.menu:first-child > li.first:first-child > a {
      display: block !important;
    }
    &.open {
      #toggle-menu {
        @include icon('arrow-up');
      }
      ul.menu {
        li, li a {
          display: block !important;
        }
      }
    }
  }

  ul.menu {
    li {
      display: block;
      margin: 0;

      @include icon('bullet') {
        @include font-size(10, 0);
        color: $color-primary;
        font-weight: 900;
        font-stretch: expanded;
        border: 4px solid $color-white;
        border-radius: 50%;
        position: absolute;
        left: -11px;
      }
      @include bp(sm, max) {
        &:before {
          left: 6px;
        }
      }
      &.active a {
        color: initial;
        font-weight: initial;

        &.active-trail.active {
          color: $color-primary;
          font-weight: 800;
        }
      }
      & a.active-trail {
        color: initial;
      }

      a {
        padding-bottom: 0.3rem;
        margin-top: 0.6rem;
        @include font-size(16);
        display: none;
        @include bp(md) {
          display: block;
        }
        &.active {
          display: block;
        }
      }

      > a {
        font-weight: 800;
      }

      ul.menu li {
        a {
          color: initial;
          font-weight: 200;
          margin-top: 0;
//          padding-left: 0.5rem;

          &.active-trail.active {
            color: $color-primary;
            font-weight: 800;
          }
        }
        &:before {
          border: none;
          content: " ";
        }

        ul.menu {
          padding-left: 1rem;
        }
      }

    }

  }

}
