.view-faq {

  .views-row {
    margin-bottom: 1rem;
  }

  .views-accordion-header {
    background-color: $color-primary;
    padding: 0.5rem;
    margin: 0;
    * {
      color: $color-white;
    }
    &:hover {
      cursor: pointer;
    }
    .field-content {
      @include icon('arrow-down') {
        padding-right: 0.5rem;
        color: $color-white;
      }
    }
    &.ui-state-active .field-content {
      @include icon('arrow-up');
    }
  }
  .ui-accordion-content {
    border: 2px solid $color-primary;
    padding: 1rem;
  }

}