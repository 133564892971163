//
// Gebied detail - op een kaart.
//

.form-item-overlays {

  @extend .clearfix;

  .form-checkboxes {
    @extend .clearfix;
  }

  #current-checkbox-wrapper {
    min-height: 1.5rem;
    padding-bottom: 1rem;

    #current-checkboxes span {
      position: relative;
      padding-right: 5px;

      &:not(:last-child) {
        &:after {
          content: ", ";
          position: absolute;
          right: 0;
          top: 0;
          padding-right: 6px;
        }
      }

    }

  }

  .selected-items-label {
    font-weight: 800;
  }

  .form-type-checkbox {
//    columns: 300px 2;

    label:before {
      border-radius: 15px;
      width: 1.1rem;
      height: 1.1rem;
      border-width: 2px;
    }

    input:checked + label:before {
      border-width: 2px;
    }

    input {
      &:focus + label:before,
      &:hover + label:before {
        border-width: 2px;
      }
    }

    &.form-item-overlays-openlayers-source-gebied-detail-gebied-detail-habitatrichtlijngebied-overlay {
      input:checked + label:before {
        background-color: #008800;
        border-color: #008800;
      }
      label:before {
        border-color: #008800;
      }
      input {
        & + label:before,
        &:focus + label:before,
        &:active + label:before,
        &:hover + label:before,
        &:checked + label:before,
        &:visited + label:before {
          border-color: #008800;
          html.svg & {
            border-color: #008800;
          }
        }
      }
    }

    &.form-item-overlays-openlayers-source-gebied-detail-gebied-detail-vogelrichtlijngebied-overlay {
      input:checked + label:before {
        background-color: #7ADEFF;
        border-color: #7ADEFF;
      }
      label:before {
        border-color: #7ADEFF;
      }
      input {
        & + label:before,
        &:focus + label:before,
        &:active + label:before,
        &:hover + label:before,
        &:checked + label:before,
        &:visited + label:before {
          border-color: #7ADEFF;
          html.svg & {
            border-color: #7ADEFF;
          }
        }
      }
    }

    &.form-item-overlays-layer-natura2000habitatkaart {
      input:checked + label:before {
        background-color: #ff9843;
        border-color: #FF9843;
      }
      label:before {
        border-color: #FF9843;
      }
      input {
        & + label:before,
        &:focus + label:before,
        &:active + label:before,
        &:hover + label:before,
        &:checked + label:before,
        &:visited + label:before {
          border-color: #FF9843;
          html.svg & {
            border-color: #FF9843;
          }
        }
      }
    }

    &.form-item-overlays-layer-natura2000habitatkaart {
      input:checked + label:before {
        background-color: $color-secondary;
        border-color: $color-secondary;
      }
      label:before {
        border-color: $color-secondary;
      }
      input {
        & + label:before,
        &:focus + label:before,
        &:active + label:before,
        &:hover + label:before,
        &:checked + label:before,
        &:visited + label:before {
          border-color: $color-secondary;
          html.svg & {
            border-color: $color-secondary;
          }
        }
      }
    }

    &.form-item-overlays-openlayers-source-gebied-detail-gebied-detail-hab3260-overlay {
      input:checked + label:before {
        background-color: $color-primary;
        border-color: $color-primary;
      }
      label:before {
        border-color: $color-primary;
      }
      input {
        & + label:before,
        &:focus + label:before,
        &:active + label:before,
        &:hover + label:before,
        &:checked + label:before,
        &:visited + label:before {
          border-color: $color-primary;
          html.svg & {
            border-color: $color-primary;
          }
        }
      }
    }

    &.form-item-overlays-layer-zoekgebied {
      input:checked + label:before {
        background-color: #E91A72;
        border-color: #E91A72;
      }
      label:before {
        border-color: #E91A72;
      }
      input {
        & + label:before,
        &:focus + label:before,
        &:active + label:before,
        &:hover + label:before,
        &:checked + label:before,
        &:visited + label:before {
          border-color: #E91A72;
          html.svg & {
            border-color: #E91A72;
          }
        }
      }
    }

  }

  // Disabled checkboxes & labelss.
  .form-type-checkbox {
    label.option:disabled {
      color: $color-muted !important;
    }

    input:disabled:before + label:disabled:before {
      background-color: $color-muted;
      border-color: $color-muted;
    }
    label:disabled:before {
      border-color: $color-muted;
    }
    input:disabled {
      & + label:before,
      &:focus + label:before,
      &:active + label:before,
      &:hover + label:before,
      &:checked + label:before,
      &:visited + label:before {
        border-color: $color-muted;
        html.svg & {
          border-color: $color-muted;
        }
      }
    }
  }

  .form-type-checkbox input:disabled + label {
    color: $color-muted;
  }

}

.map-message {
  padding-top: 1rem;
}