
// Settings
// --------------------------------------------------

// Fonts

$font-path:               '../fonts/';
$icon-font:               'icons';
$icon-font-2:             'glyphicons';

$default-font:            'FlandersArtSerif-Regular';
$default-font-light:      'FlandersArtSerif-Light';
$default-font-medium:     'FlandersArtSerif-Medium';
$default-font-bold:       'FlandersArtSerif-Bold';

$secondary-font:          'FlandersArtSans-Regular';
$secondary-font-light:    'FlandersArtSans-Light';
$secondary-font-medium:   'FlandersArtSans-Medium';
$secondary-font-bold:     'FlandersArtSans-Bold';

$font-fallback:           'Arial', 'Helvetica', 'sans-serif';

// Font sizes

$default-font-size: 	  16;
$icon-font-size: 	      18;
$line-height-factor:      1.2;

// Grid

$grid-columns:  		      12;
$max-site-width:  		      1400px;
$gutter:        			  16px;
$default-block-margin-bottom: 3rem;

// Breakpoints

$xs-container-width: 	300px;
$xs-width: 					$xs-container-width + 92;

$sm-container-width: 	450px;
$sm-width:      			$sm-container-width + 92;

$md-container-width: 	600px;
$md-width:      			$md-container-width + 92;

$lg-container-width: 	900px;
$lg-width:      			$lg-container-width + 92;

$xl-container-width: 	1048px;
$xl-width:      			$xl-container-width + 92;

// Colors

$color-primary:           #0a718c; // main brand color
$color-primary-rgb:       10, 113, 140; // main brand color
$color-secondary:         #8ac73f; // secondary brand color
$color-background:        #ffffff; // page background
$color-background-dark:   #333333; // page background

$color-primary-muted: darken($color-primary, 3);

$color-white:       #ffffff;
$color-black:       #021710;
$color-grey:        #e6e7e8; // grey backgrounds like inactive tabs etc.

$color-text:        #392d02; // default text color
$color-muted:       #9c9c9c; // muted text like descriptions etc.
$color-link:        $color-primary;
$color-link-hover:  #333333;

$color-status:      #1bb761; // status messages
$color-warning:     #ffa956; // warning messages
$color-error:       #f14d51; // error messages

$color-border:      #e1e1e1; // default border color, 
$color-form-border: $color-border; // border color for input elements

// Borders

$default-border: 		  solid 1px $color-border;
$default-border-radius:   4px;

// Forms

$form-font-size:      $default-font-size;
$form-padding:        0.6rem;
$form-border:         solid 1px $color-form-border;
$form-border-radius:  1px;
$color-form-hover:    $color-link-hover;
$color-form-focus:    $color-link;
$color-form-shadow:   $color-grey;

// Buttons

$color-button:          #555555;
$button-border:	        solid 1px #bbbbbb;
$button-border-radius:  $form-border-radius;


// Transition
// More examples at http://easings.net/

$easeIn:                    cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOut:                   cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOut:                 cubic-bezier(0.645, 0.045, 0.355, 1);

$default-transition-time:   0.2s;
$slow-transition-time:      0.6s;
$default-transition:        $easeOut $default-transition-time;
$slow-transition:           $easeOut $slow-transition-time;


// Animations (see _animations.scss for full code)

.animated {
  -webkit-animation-duration: $default-transition-time;
  animation-duration: $default-transition-time;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
}
