
// Width Indicator
// --------------------------------------------------

body.breakpoint-indicator:after {
    content: "--";
    position: fixed;
    right: 10px;
    bottom: 10px;
    
    width: 32px;
    padding: 4px;
    border-radius: 4px;
    text-align: center;
    font-family: "Open Sans", "Segoe UI", "Helvetica", sans-serif;
    font-size: 12px;
    color: #fff;   
    background-color: rgba(#000, 0.8);

    @include bp(xs) {
        content: "xs";
    }
    @include bp(sm) {
        content: "sm";
    }
    @include bp(md) {
        content: "md";
    }
    @include bp(lg) {
        content: "lg";
    }
    @include bp(xl) {
        content: "xl";
    }
}
