
// Messages
// --------------------------------------------------

.messages,
.status-messages,
.filter-guidelines {
	padding: 0.5rem 1rem;
	border-left: solid 5px;
	border-color: $color-border;
	background-color: $color-grey;
	&.status,
	&.status--status {
		border-color: $color-status;
		background-color: lighten($color-status, 20%);
	}
	&.warning,
	&.status--warning {
		border-color: $color-warning;
		background-color: lighten($color-warning, 20%);
	}
	&.error,
	&.status--error {
		border-color: $color-error;
		background-color: lighten($color-error, 20%);
	}
}

.messages {
	margin-bottom: 1rem;
}

.status-messages {
	margin-bottom: $default-block-margin-bottom;
}

.filter-guidelines {
	margin-bottom: $default-block-margin-bottom;
	ul {
		margin: 0;
	}
	li {
		@extend .description;		
	}
}