.pswp__button--arrow--left,
.pswp__button--arrow--right {
  &:before {
    background-color: rgba($color-primary, 0.8);
  }
}

.pswp__ui--fit {
  .pswp__top-bar,
  .pswp__caption {
    background-color: rgba($color-primary, 0.8);
    .pswp__caption__center {
      color: $color-white;
    }
  }
}