
// Fonts
// --------------------------------------------------

// Font weights; more info at https://www.w3.org/TR/css-fonts-3/
// 100 - Thin
// 200 - Extra Light (Ultra Light)
// 300 - Light
// 400 - Normal
// 500 - Medium
// 600 - Semi Bold (Demi Bold)
// 700 - Bold
// 800 - Extra Bold (Ultra Bold)
// 900 - Black (Heavy)

@mixin font-face($name, $font-weight, $font-style, $filename) {
    @font-face {
        font-family: $name;
        src: url('#{$font-path}#{$filename}.eot');
        src: url('#{$font-path}#{$filename}.eot?#iefix') format('embedded-opentype'),
             // url('#{$font-path}#{$filename}.woff2') format('woff2'),
             url('#{$font-path}#{$filename}.woff') format('woff'),
             url('#{$font-path}#{$filename}.ttf') format('truetype'),
             url('#{$font-path}#{$filename}.svg##{$filename}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

// ICON FONT
@include font-face('icons', normal, normal, 'icons');

// Default font
@include font-face($default-font, normal, normal, $default-font);
@include font-face($default-font-light, 200, normal, $default-font-light);
@include font-face($default-font-medium, normal, normal, $default-font-medium);
@include font-face($default-font-bold, normal, normal, $default-font-bold);

// Secondary font
@include font-face($secondary-font, normal, normal, $secondary-font);
@include font-face($secondary-font-light, normal, normal, $secondary-font-light);
@include font-face($secondary-font-medium, normal, normal, $secondary-font-medium);
@include font-face($secondary-font-bold, normal, normal, $secondary-font-bold);
