
// Throbber
// --------------------------------------------------

html.js input.form-autocomplete,
html.js input.throbbing {
  background-image: none;
}

// these apply to all ajax progresses
.ajax-progress {
  display: inline;
}
.ajax-progress .throbber {
  display: inline-block;
  margin: 0;
  margin-top: -3px;
  padding: 0;
  height: 15px;
  width: 15px;
  background-color: transparent;
  background-image: url('../images/throbber.gif');
  background-repeat: no-repeat;
  background-position: 0px -15px;
  background-size: 100% auto;
}
.pager .throbber {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.ajax-progress-throbber .message {
  display: none;
}
