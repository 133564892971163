.node-type-soort {

  .row-content .panel-pane {
    float: left;
    margin-bottom: 1rem;
    width: 100%;
  }

  .pane-node-field-officiele-naam,
  .pane-node-field-fauna-flora,
  .pane-node-field-categorie-soort {
    float: left;
    color: $color-muted;
    padding-right: 0.5rem;
    @include font-size(20);
    width: auto !important;
    &:after {
      content: ", ";
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 4px;
    }
  }

  .pane-node-field-officiele-naam {
  }
  .pane-node-field-fauna-flora {
  }
  .pane-node-field-categorie-soort {
    &:after {
      content: ' ';
    }
  }

  .pane-node-field-afbeeldingen {
    float: left;
    display: block;
    margin-right: 1rem;
    width: 100%;

    @include bp(sm) {
      margin-right: 0rem;
    }

    .field-item {
      float: left;
      margin-right: 1rem;
      display: none;

      width: 100%;
      @include bp(sm) {
        width: 50%;
      }
      @include bp(md) {
        width: 45%;
      }
      @include bp(lg) {
        width: auto;
      }

      @include bp(sm, max) {
        &:nth-child(-n+1) {
          display: block;
        }
      }
      @include bp(sm) {
        &:nth-child(-n+2) {
          display: block;
        }
      }

    }

    .field--afbeeldingen {
      @include bp(sm) {
        float: left;
      }

      a, img {
        display: block;
        margin: auto auto;
        text-align: center;
      }
    }

  }

  // Show all images button.
  .show-more-images.button {
    width: 100%;
    float: left;
    //  margin: 1rem 0;

    @include bp(sm) {
      //@extend .button-primary;
      color: $color-white;
      background-color: $color-primary;
      border: solid 1px $color-primary;
  
      &:hover,
      &:focus {
        color: $color-white;
        background-color: lighten($color-primary, 10%);
        border-color: lighten($color-primary, 10%);
      }
  
      position: absolute;
      bottom: 0;
      margin: 0;
      width: 100%;
    }
  }

  .species-in-flanders-doelen-labels {
    .node-soort-vlaanderen {
      background-color: $color-secondary;
      padding: 1rem 1rem 0 1rem;
      color: $color-white;
      display: inline-block;
    }

  }
  .species-in-flanders-doelen-info {
    float: left;
  }

  .pane-soorten-species-in-flanders-info {
    margin: 0;

    .views-row {
      padding: 0;
    }

    .node-teaser {
      margin-bottom: 1rem;
    }

    .group-target-labels {
      background-color: $color-secondary;
      padding: 1rem 1rem 0 1rem;
      color: $color-white;
      float: left;
      width: 100%;

      @include bp(sm) {
        width: 250px;
      }
      display: block;
      margin-right: 1rem;
      margin-bottom: 0.6rem;

      .field-label {
        @include font-size(18);
        font-weight: 600;
        //        margin-bottom: 0.5rem;
      }

      .target-label-icon {
        margin-bottom: 1rem;
        p {
          font-size: 0.1px;
          font-size: 0;
          height: 0;
          margin-bottom: 0;
        }
        &:before {
          padding-right: 0.5rem;
        }
        &.plus {
          @include icon('kind-arrow');
        }
        &.minus {
          @include icon('kind-arrow-down');
        }
        &.same {
          &:before {
            content: "=";
            @include font-size(22, 0);
            margin: 0 4px;
          }
        }
      }

    }

    .group-target-descriptions {
      .field-label, div[class*='field--'] p {
        margin-bottom: 0.5rem;
      }
    }

  }

}


.pane-node-field-id-prioritaire-inspanning,
.pane-node-field-id-van-habitattypes,
.pane-node-field-id-van-soorten,
.pane-habitattypes-habitattype-gebieden,
.pane-habitattypes-habitattype-inspanningen,
.pane-habitattypes-habitattype-natuurdoelen,
.pane-soorten-areas-connected-to-species,
.pane-soorten-efforts-connected-to-species,
.pane-soorten-goals-connected-to-species {
  h2 {
    @include font-size(20);
    font-family: $secondary-font;
    font-weight: 400;
  }
}