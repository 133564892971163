// Begrippen pagina
// --------------------------------------------------

.page-begrippen {

  #begrippen {
    @extend .clearfix;
    min-height: 800px; // give the begrippen page content a min height of 700px so the lexicon letters fit nicly on it.
  }

  .lexicon-links {
    font-size: 0.1px;
    font-size: 0;
    line-height: 0;
    text-align: center;

    .lexicon-item {
      @include font-size(24, 34);

      &.active {
        color: $color-black;
        font-weight: 800;
      }

    }

  }

  @include bp(sm, max) {
    .lexicon-links {
      position: absolute;
      display: block;
      float: right;
      right: 0;
      z-index: 30;
      border-color: $color-grey;
      border-style: solid;
      border-width: 1px 0 1px 1px;

      .lexicon-item {
        vertical-align: middle;
        display: block;
        padding: 0 0.5rem;

        &:hover, &.active {
          background-color: $color-grey;
        }

        border-bottom: 1px solid $color-grey;
        &:last-child {
          border-bottom: none;
        }

      }
    }
    .lexicon-list {
      padding-right: 1.5rem;
    }

  }

  @include bp(md) {

    .lexicon-links {
      margin: 2rem;
      padding-bottom: 1rem;
      border-bottom: $default-border;

      .lexicon-item {
        @include font-size(30);
        padding: 0.5rem;
      }

    }

    .lexicon-alphabar-instructions {
      @include col;
      @include cols(10);
      @include cols-offset(1);
    }

    .lexicon-list {
      margin-top: 2rem;
      @include col;
      @include cols(10);
      @include cols-offset(1);

      dt {
        @include font-size(20);
        font-weight: 800;
        padding-bottom: 0.3rem;
      }
    }

  }

}