
// Typography
// --------------------------------------------------

// Spacing

pre, blockquote, dl, figure, table, p, ul, ol, form {
    margin-bottom: 1.5rem;
}

// Headings

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: $default-font-light, $font-fallback;
  a {
    color: inherit;
  }
}

h1 {
  @include font-size(24);
  margin-bottom: 2rem;
  @include bp(md) {
    @include font-size(36);
  }
  @include bp(lg) {
    @include font-size(48);
  }
}

h2 {
  @include font-size(24);
  font-weight: 600;
  @include bp(md) {
    @include font-size(30);
  }
  @include bp(lg) {
    @include font-size(36);
  }
}

h3 {
  @include font-size(18);
  font-weight: 500;
  @include bp(md) {
    @include font-size(20);
  }
  @include bp(lg) {
    @include font-size(24);
  }
}

h4 {
  @include font-size(16);
  font-weight: 500;
  @include bp(lg) {
    @include font-size(18);
  }
}

h5 {
  @include font-size(16);
  font-weight: 500;
}

h6 {
  @include font-size(16);
  font-weight: 400;
}

p {
    margin-top: 0;
}

.lead {
  @include font-size(18);
  font-weight: 600;
  margin-bottom: 2rem;
  max-width: 600px;
}

.muted {
    color: $color-muted;
}

.small {
    @include font-size(12);
}

.description {
    @include font-size(12);
    color: $color-muted;
}

a {
  color: $color-link;
  text-decoration: none;
  transition: $default-transition;
  &:hover {
    color: $color-link-hover;
    text-decoration: none;
  }
}

a.inline-link {
  color: $color-text;
//  font-weight: 600;
  border-bottom: solid 1px $color-link;
  word-wrap: break-word;
}

p, a {
  &[class^='icon-']:before,
  &[class*=' icon-']:before {
    margin-right: 0.4rem;
    text-decoration: none;
  }
  &[class^='icon-']:after,
  &[class*=' icon-']:after {
    margin-left: 0.4rem;
    text-decoration: none;
  }
}

// Labels

label {
  font-weight: 700;
}

// Marker

.marker {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.2em; 
  @include font-size(12, 0);
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-white;
  background-color: $color-warning; 
  box-shadow: 0.2rem 0 0 $color-warning, -0.2rem 0 0 $color-warning; 
}

// Pills

.pill {
  display: inline-block;
  margin: 0rem .3rem .3rem 0rem;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  background: $color-grey;
  color: $color-text; 
  &:before {
    text-decoration: none;
    margin-right: 0.5rem;
  }
}
a.pill:hover {
  color: $color-white;
  background-color: $color-link-hover;
}

.pill-primary {
  color: $color-white;
  background-color: $color-primary;
}
a.pill-primary:hover {
  background-color: $color-link-hover;
}
.pill-secondary {
  color: $color-text;
  background-color: $color-secondary;
}
a.pill-secondary:hover {
  background-color: $color-link-hover;
}

// Description lists

dl {
  margin-bottom: 1rem;
  dt {
    font-weight: 600;
  }
  dd {
    margin-left: 2rem;
  }
}
dl.dl-horizontal {
  @include bp(sm) {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 10rem;
    }
    dd {
      margin-left: 10rem + 1;
    }
  }
}
dl.dl-horizontal-narrow {
  @include bp(sm) {
    dt {
      float: left;
      overflow: hidden;
      clear: left;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 6rem;
    }
    dd {
      margin-left: 6rem + 1;
    }
  }
}

// Read more link

a.read-more {
  font-weight: 600;
  @include icon('arrow-right', after);
  &:after {
    @include font-size(14);
    color: $color-text;
    margin-left: 0.4rem;  
    text-decoration: none;
  }
}

// Lists

ul {
    list-style: circle inside;
}

ol {
    list-style: decimal inside;
    padding-left: 0;
    margin-top: 0;
}

ul {
    padding-left: 0;
    margin-top: 0;
    ul, ol {
        margin: 1.5rem 0 1.5rem 3rem;
        font-size: 90%;
    }
}

ol {
    ol, ul {
        margin: 1.5rem 0 1.5rem 3rem;
        font-size: 90%;
    }
}

// Button groups

ul.links,
.form-actions {
    @extend .clearfix;
    > li,
    >input {
        float: left;
        margin-right: 1rem;
    }
}

// Tables

table {
  max-width: 100%;
  border-collapse: collapse;
}
table.full-width {
  width: 100%;
}

th {
    font-weight: 600;
}

th, td {
    padding: 0.75rem 1rem;
    text-align: left;
    vertical-align: top;
    border-bottom: $default-border;
}

table.vline th, 
table.vline td {
  border: $default-border;
}

table.striped {
  background-color: $color-white;
  tbody > tr:nth-child(odd) > td, 
  tbody > tr:nth-child(odd) > th {
    background-color: $color-grey;
  }
}

table.row-links tbody {
  th, td {
    transition: background-color $default-transition, color $default-transition;
  }
  tr:hover th, tr:hover td {
    color: $color-link-hover;
    background-color: lighten($color-link-hover, 50%);
    cursor: pointer;
  }
}

// Blockquote

blockquote {
  margin: 2rem 0 2rem 0;
  padding: 0 0 0 1rem;
  border-left: solid 3px $color-primary;
  > p {
    @include font-size(18);
    font-weight: 700;
    margin-bottom: 0;
  }
  .small {
    margin-top: 0.5rem;
    color: $color-muted;
    &:before {
      content: '\2014 \00A0';
    }
  }
  @include bp(md) {
    p {
      @include font-size(24);
    }
  }
}

// Images

img {
  max-width: 100%;
  height: auto;
  border: 0 none;
}

.inline-image {
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
  &.float-right {
    float: right;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}