// Homepage
// --------------------------------------------------

body.front {

  .region--content {
    padding-top: 6rem;
    @include bp(md) {
      padding-top: 22rem;
    }

    .container {
      background: transparent;
      padding-top: 0;
    }
  }

  .postalcode-search, .about-natura2000 {
    @extend .clearfix;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.4);
    min-height: 7rem;
    margin-bottom: 1rem;
    @include bp(lg) {
      margin-bottom: 3rem;
    }

    h2 {
      @include font-size(20);
    }

  }

  .postalcode-search {
    background-color: $color-white;
    padding: 1rem;

    #edit-locate-me.form-item {
      text-align: center;
      margin-bottom: 0.5rem;
      @include bp(lg) {
        position: absolute;
        bottom: 0;
        text-align: left;
        margin-bottom: 1rem;
      }

      text-decoration: underline;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }

    }
  }

  .about-natura2000 {
    color: $color-white;
    padding: 1rem;

    background: rgba($color-secondary, 1);
    @include bp(md) {
      background: rgba($color-secondary, 0.9);
    }

    & {
      background-image: url('../images/swans.png');
      background-repeat: no-repeat;
      background-position: 99% 91%;
      background-size: 33%;
    }

    .node {
      margin-bottom: 0;
      padding-bottom: 3rem;
      position: relative;
    }

    .field--body a {
      color: $color-white;
      text-decoration: underline;
      border-bottom: none;

      &:hover {
        color: $color-white;
        text-decoration: none;
      }
    }

    .field--node-link a,
    .node-readmore a {
      color: $color-white;
      text-decoration: none;
      position: absolute;
      bottom: 0;

//      @include icon('arrow-right', after) {
//        @include font-size(12, 0);
//        padding-left: 0.3rem;
//        vertical-align: middle;
//        color: $color-white;
//        text-decoration: none;
//      }

      &:hover:before {
        text-decoration: none;
      }
      &:hover {
        color: $color-white;
        text-decoration: underline;
      }
    }
  }

  .info-message-block {
    padding: 1rem;
    border: 4px solid $color-secondary;
  }

  .newsletter-signup-block {
    @extend .col;
    @extend .col-xs-12;
    @extend .col-md-10;
    @extend .col-md-offset-1;

    .pane-content {
      @extend .col;
      @extend .col-xs-12;
      @extend .col-lg-10;
      @extend .col-lg-offset-1;
    }

    form {
      @include bp(sm, max) {
        text-align: center;
      }
    }

    .url-textfield {
      display: none;
    }

    .node {
      margin-bottom: 0;
    }

    background-color: rgba($color-white, 0.8);
    padding: 2rem 1rem;

    div[id*='webform-component-'], .form-actions {
      @extend .col;
      @extend .col-xs-12;
      @extend .col-md-4;
    }

    input {
      border: none;
      border-bottom: 2px solid $color-primary;
      background: transparent;
      &:focus, &:active {
        outline: none;
        box-shadow: none;
        //        border-bottom: 2px solid $color-secondary;
      }
    }

    button {
      @extend .button-primary;
      margin-bottom: 0.5rem;
      padding: 0.6rem 3.5rem;
    }

  }

  .row-content-b {
    .col {
      position: relative;
    }

    .panel-pane {
      position: relative;
      background-repeat: no-repeat;
      background-size: 80px;
      background-position-x: right;
      background-position-y: 30px;

      &.pane-nieuwsberichten-news-most-recent {
        background-image: url('../images/newspaper-01.png');
      }
      &.pane-projecten-projects-most-recent {
        background-image: url('../images/spade-01.png');
      }
      &.pane-over-pagina-about-front-page-list {
        background-image: url('../images/book-01.png');
      }

      .pane-title {
        margin-top: 1rem;
        @include font-size(22);
      }

      .pane-content {
        padding-bottom: 3rem;
        width: 70%;
        @include bp(md) {
          width: 100%;
        }
      }

      .views-row {
        margin-bottom: 0.5rem;
        a {
          @include icon('arrow-right', after) {
            @include font-size(12, 0);
            padding-left: 0.3rem;
            vertical-align: middle;
          }
        }
      }

      .more-link {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;

        a {
          @extend .button;
          @extend .button-primary;

          margin: 1rem 0 0 0;
          padding: 0.5rem 1rem;

          &:after {
            content: "";
            margin: 0 !important;
          }
        }
      }

      @include bp(md) {
        background-position-y: initial;
        background-position: center top;
        background-size: 120px;

        .pane-title {
          padding-top: 10rem;
        }
      }

    }

  }

  .view-spotlight {
    @extend .row;

    .views-row {
      @extend .col;
      @extend .col-md-4;
    }

    .views-row-1 .node-teaser {
      background-image: url('../images/home_4_kleurenANB_03.jpg');
    }
    .views-row-2 .node-teaser {
      background-image: url('../images/home_4_kleurenANB_05.jpg');
    }
    .views-row-3 .node-teaser {
      background-image: url('../images/home_4_kleurenANB_07.jpg');
    }

  }

}