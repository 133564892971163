// Search
// --------------------------------------------------

// Main site search in header
.main-search {
  display: none;
  &.open {
    display: block;
  }
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: 5rem;
  padding: 1rem;
  background-color: $color-white;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid $color-grey;
  border-left: 1px solid $color-grey;

  @include bp(md) {
    width: 25rem;
    top: 75px;
    margin-top: 0;
  }
  .content {
    text-align: center;
  }
  .views-exposed-widgets {
    display: inline-block;
  }

  label {
    display: none;
  }
  .views-exposed-widget {
    float: left;
    width: 77%;
    @include bp(sm) {
      width: auto;
    }
  }
  .views-exposed-options {
    float: left;
  }
  form, .form-item, button {
    margin: 0;
  }

  .form-text {
    height: 2.9rem;
    width: auto;
    max-height: 46px;
  }

  .form-submit {
    @extend .button;
    @extend .button-primary;
    margin-bottom: 0;
    max-height: 46px;
    padding: 0.6rem 1rem;

    position: relative;
    font-size: 0.1px;
    font-size: 0px;
    background-color: $color-primary;

    @include icon('search') {
      @include font-size(24, 0);
      color: $color-white;
    }

  }

}

// Postalcode search on homepage
.postalcode-search {
  form {
    @extend .clearfix;
    text-align: center;
    margin-bottom: 0.5rem;
    @include bp(lg) {
      margin-top: 2rem;
    }
  }

  .form-item-postal-code-wrapper {
    @extend .clearfix;
    @extend .col;
    @extend .col-lg-8;
    position: relative;

    span.locate-me-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      &:hover {
        cursor: pointer;
      }
      a {
        &:before {
          @include font-size(24, 0);
          margin-top: 5px;
        }
      }
    }
  }

  button.form-submit {
    @extend .col;
    @extend .col-lg-4;
  }

  .form-item-postal-code {
    @extend .clearfix;
    margin-bottom: 0.5rem;
    //    width: 100%;

    //    @include bp(xl) {
    //      width: 63%;
    //    }

    label {
      display: none;
    }

    .form-text {
      width: 100%;
      float: left;
      border: none;
      border-bottom: 1px solid $color-primary;
      padding-right: 2rem;

      &:focus, &:active {
        outline: none;
        box-shadow: none;
      }
    }

  }

  .form-submit {
    @extend .button;
    @extend .button-primary;
    margin-top: 1rem;

    @include bp(lg) {
      margin-top: 0;
      float: right;
    }
    background-color: $color-primary;
    color: $color-white;
  }

}

// Search results page
.view-search {
  @extend .col;
  @extend .col-md-10;
  @extend .col-md-offset-1;

  .views-row {
    padding: 1rem;
    background-color: $color-grey;
    margin-bottom: 1rem;

    h2 {
      @include font-size(22);
      color: $color-primary;
    }
  }

  .field--datum {
    padding-bottom: 1rem;
  }

  .field--afbeelding {
    float: left;
    padding: 0 1rem 1rem 0;
  }
}

// Fading effects

//
// fadeInRight
//
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

//
// fadeOutRight
//
@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}