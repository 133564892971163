// Pager
// --------------------------------------------------

.item-list {
  @extend .clearfix;
  text-align: center;
}

ul.pager {
  list-style: none;
  display: inline-block;
  margin-top: 3rem;
  position: relative;

  &:before {
    content: "";
    height: 2px;
    display: block;
    width: 133%;
    position: absolute;
    left: -50px;
    top: -2px;

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    // Fallbacks for all browsers
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(23%, rgba(10, 113, 140, 1)), color-stop(80%, rgba(10, 113, 140, 1)), color-stop(100%, rgba(10, 113, 140, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#0a718c', GradientType=1);

  }

  li {
    position: relative;
    padding: 0 !important;
    display: block;
    float: left;
    margin: 0 0.5rem 0 0.5rem;
    text-align: center;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    @include icon('bullet') {
      @include font-size(10, 0);
      color: $color-primary;
      font-weight: 900;
      font-stretch: expanded;
      border: 4px solid $color-white;
      border-radius: 50%;
      position: absolute;
      top: -9px;
      left: 17%;
    }
    &:hover:before {
      color: $color-white;
      border-color: $color-primary;
      // The (nice, smooth) transition causes the effect to lag & stay on elements for no reason, so remove for now.
      //      transition: color $default-transition, background-color $default-transition, border $default-transition;
    }

    a, > span {
      display: block;
      position: absolute;
      top: -7px;
      padding: 1rem 0.5rem 0 0.5rem;
    }

    &.pager-first a {
      left: -34px;
    }
    &.pager-previous a {
    }
    &.pager-next a {
    }
    &.pager-last a {
    }

    &.pager-first,
    &.pager-previous,
    &.pager-next,
    &.pager-last {
      a {
        margin: 0;
        padding-top: 16px;
        vertical-align: bottom;
        width: 1.5rem;
        height: 1.5rem;

        &:before {
          @include font-size(12, 0);
        }

      }
    }

    &.pager-item a {
    }

    &.pager-current {
      &:before {
        color: $color-white;
        border-color: $color-primary;
      }
    }
    &.pager-last {
      margin-right: 1rem;
    }

  }

}

// Mini pager

// @TODO create class for ul.pager.mini-pager
// for now, add occurences of your mini pagers below
ul.mini-pager {
  .pager-current {
    background: none;
    min-width: none;
    padding: 0 0.5rem;
  }
}

// Load more pager.

ul.pager-show-more,
ul.pager-load-more {
  //  @extend .col;
  //  @extend .col-md-6;
  //  @extend .col-md-offset-6;
  //  margin-top: auto;
  //  padding: 0;
  width: 100%;
  float: left;
  margin-top: 0;

  &:before {
    content: " ";
    background: none;
    height: auto;
    width: auto;
    position: relative;
    left: auto;
    right: auto;
    &:hover {
      border: none;
      border-color: transparent;
    }
  }

  li {
    @include reset-list;
    background: none;
    height: auto;
    width: auto;
    position: relative;
    left: auto;
    right: auto;
    border: none;
    border-radius: 0%;
    min-width: auto;

    &:before {
      content: " ";
      background: none;
      height: auto;
      width: auto;
      position: relative;
      left: auto;
      right: auto;
      border: none;
      border-radius: 0%;
    }
    &:hover:before {
      border: none;
      border-color: transparent;
    }

    &.pager-show-more-next {
      @extend .col-xs-6;
      @extend .col-sm-4;
      @extend .col-md-2;
//      @extend .col-md-offset-6;
//      padding-left: 2rem !important;

      a {
        display: block;
        position: relative;
        top: auto;
        height: auto;
        width: auto;
        position: relative;
        border: none;
        border-color: transparent;
        border-radius: 0%;
        vertical-align: initial;
        padding: 0.5rem 1rem;

        @extend .button;
        @extend .button-primary;
      }

    }

  }

}