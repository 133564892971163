
// Grid
//--------------------------

.container {
	position: relative;
	margin: auto;
	padding-left: $gutter;
	padding-right: $gutter;
	max-width: $max-site-width;
	// fixed widths
	// @include bp(xs) {
	// 	width: $xs-container-width;
	// }
	// @include bp(sm) {
	// 	width: $sm-container-width;
	// }
	// @include bp(md) {
	// 	width: $md-container-width;
	// }
	// @include bp(lg) {
	// 	width: $lg-container-width;
	// }
	// @include bp(xl) {
	// 	width: $xl-container-width;
	// }
}


.region--toolbar,
.region--header,
.header-logo-wrapper,
.region--content,
.region--footer {
	.container {
		@include bp(md) {
			padding: 0 2rem;
		}
	}
}
// Overrides for special elements (menu items on the right side)
.region--toolbar .container {
	padding-right: 1.5rem;
}
.region--header .container {
	padding-right: 1rem;
}

.row {
	@extend .clearfix;
	margin-left: -$gutter/2;
	margin-right: -$gutter/2;
}

.col, *[class^="col-"] {
	position: relative;
	float: left;
	width: 100%;
	padding-left: $gutter/2;
	padding-right: $gutter/2;
}

// col classes

@for $i from 1 through $grid-columns { 
	.col-#{$i} { width: 100% / (12/$i); }
}	
@include bp(xs) {
	@for $i from 1 through $grid-columns { 
		.col-xs-#{$i} { width: 100% / (12/$i); }
	}	
}
@include bp(sm) {
	@for $i from 1 through $grid-columns { 
		.col-sm-#{$i} { width: 100% / (12/$i); }
	}	
}
@include bp(md) {
	@for $i from 1 through $grid-columns { 
		.col-md-#{$i} { width: 100% / (12/$i); }
	}	
}
@include bp(lg) {
	@for $i from 1 through $grid-columns { 
		.col-lg-#{$i} { width: 100% / (12/$i); }
	}	
}
@include bp(xl) {
	@for $i from 1 through $grid-columns { 
		.col-xl-#{$i} { width: 100% / (12/$i); }
	}	
}

// offset classes

@for $i from 1 through $grid-columns { 
	.col-offset-#{$i} { margin-left: 100% / (12/$i); }
}	
@include bp(xs) {
	@for $i from 1 through $grid-columns { 
		.col-xs-offset-#{$i} { margin-left: 100% / (12/$i); }
	}	
}
@include bp(sm) {
	@for $i from 1 through $grid-columns { 
		.col-sm-offset-#{$i} { margin-left: 100% / (12/$i); }
	}	
}
@include bp(md) {
	@for $i from 1 through $grid-columns { 
		.col-md-offset-#{$i} { margin-left: 100% / (12/$i); }
	}	
}
@include bp(lg) {
	@for $i from 1 through $grid-columns { 
		.col-lg-offset-#{$i} { margin-left: 100% / (12/$i); }
	}	
}
@include bp(xl) {
	@for $i from 1 through $grid-columns { 
		.col-xl-offset-#{$i} { margin-left: 100% / (12/$i); }
	}	
}

// push classes

@for $i from 1 through $grid-columns { 
	.col-push-#{$i} { left: 100% / (12/$i); }
}	
@include bp(xs) {
	@for $i from 1 through $grid-columns { 
		.col-xs-push-#{$i} { left: 100% / (12/$i); }
	}	
}
@include bp(sm) {
	@for $i from 1 through $grid-columns { 
		.col-sm-push-#{$i} { left: 100% / (12/$i); }
	}	
}
@include bp(md) {
	@for $i from 1 through $grid-columns { 
		.col-md-push-#{$i} { left: 100% / (12/$i); }
	}	
}
@include bp(lg) {
	@for $i from 1 through $grid-columns { 
		.col-lg-push-#{$i} { left: 100% / (12/$i); }
	}	
}
@include bp(xl) {
	@for $i from 1 through $grid-columns { 
		.col-xl-push-#{$i} { left: 100% / (12/$i); }
	}	
}

// pull classes

@for $i from 1 through $grid-columns { 
	.col-pull-#{$i} { right: 100% / (12/$i); }
}	
@include bp(xs) {
	@for $i from 1 through $grid-columns { 
		.col-xs-pull-#{$i} { right: 100% / (12/$i); }
	}	
}
@include bp(sm) {
	@for $i from 1 through $grid-columns { 
		.col-sm-pull-#{$i} { right: 100% / (12/$i); }
	}	
}
@include bp(md) {
	@for $i from 1 through $grid-columns { 
		.col-md-pull-#{$i} { right: 100% / (12/$i); }
	}	
}
@include bp(lg) {
	@for $i from 1 through $grid-columns { 
		.col-lg-pull-#{$i} { right: 100% / (12/$i); }
	}	
}
@include bp(xl) {
	@for $i from 1 through $grid-columns { 
		.col-xl-pull-#{$i} { right: 100% / (12/$i); }
	}	
}