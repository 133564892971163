

#addemar-subscription-subscribe-form{
	.form-item{
		float:left;
		width: 33%;
		padding-left: 8px;
		padding-right: 8px;
	}
	label{
		display:none;
	}

}