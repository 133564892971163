//
// Icons: AUTOMATICALLY GENERATED BY GULP
// --------------------------------------------------

.icon:before, [class^="icon-"]:before, [class^="icon-"]:after, [class*=" icon-"]:before, [class*=" icon-"]:after {
  font-family: "icons";
  speak: none;
  @include font-size(18, 0);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 1;
}

.icon-arrow-bottom-double:before,
.icon-arrow-down:before,
.icon-arrow-left-double:before,
.icon-arrow-left:before,
.icon-arrow-right-bold:before,
.icon-arrow-right-double:before,
.icon-arrow-right:before,
.icon-arrow-top-double:before,
.icon-arrow-up:before,
.icon-book:before,
.icon-bubbles:before,
.icon-bullet:before,
.icon-calendar:before,
.icon-circle:before,
.icon-clipboard:before,
.icon-close:before,
.icon-comment:before,
.icon-comments:before,
.icon-dashboard:before,
.icon-edit:before,
.icon-extlink:before,
.icon-file:before,
.icon-files:before,
.icon-grid:before,
.icon-handle:before,
.icon-heart:before,
.icon-help:before,
.icon-kind-arrow-down:before,
.icon-kind-arrow:before,
.icon-license:before,
.icon-lightning:before,
.icon-line:before,
.icon-list:before,
.icon-lock-open:before,
.icon-lock:before,
.icon-logout:before,
.icon-mail:before,
.icon-map-marker:before,
.icon-marker:before,
.icon-menu:before,
.icon-minus:before,
.icon-more:before,
.icon-newspaper:before,
.icon-phone:before,
.icon-plus:before,
.icon-print:before,
.icon-read-more:before,
.icon-reset:before,
.icon-search:before,
.icon-social-facebook:before,
.icon-social-google:before,
.icon-social-linkedin:before,
.icon-social-twitter:before,
.icon-spade:before,
.icon-star-fill:before,
.icon-star-half:before,
.icon-star:before,
.icon-stats:before,
.icon-status-error:before,
.icon-status-help:before,
.icon-status-info:before,
.icon-status-warning:before,
.icon-tag:before,
.icon-target:before,
.icon-time:before,
.icon-trash:before,
.icon-triangle-down-full:before,
.icon-triangle-down:before,
.icon-triangle-left:before,
.icon-triangle-right:before,
.icon-triangle-up:before,
.icon-user:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.icon-arrow-bottom-double:before { content: "\E001" }
.icon-arrow-down:before { content: "\E002" }
.icon-arrow-left-double:before { content: "\E003" }
.icon-arrow-left:before { content: "\E004" }
.icon-arrow-right-bold:before { content: "\E005" }
.icon-arrow-right-double:before { content: "\E006" }
.icon-arrow-right:before { content: "\E007" }
.icon-arrow-top-double:before { content: "\E008" }
.icon-arrow-up:before { content: "\E009" }
.icon-book:before { content: "\E00A" }
.icon-bubbles:before { content: "\E00B" }
.icon-bullet:before { content: "\E00C" }
.icon-calendar:before { content: "\E00D" }
.icon-circle:before { content: "\E00E" }
.icon-clipboard:before { content: "\E00F" }
.icon-close:before { content: "\E010" }
.icon-comment:before { content: "\E011" }
.icon-comments:before { content: "\E012" }
.icon-dashboard:before { content: "\E013" }
.icon-edit:before { content: "\E014" }
.icon-extlink:before { content: "\E015" }
.icon-file:before { content: "\E016" }
.icon-files:before { content: "\E017" }
.icon-grid:before { content: "\E018" }
.icon-handle:before { content: "\E019" }
.icon-heart:before { content: "\E01A" }
.icon-help:before { content: "\E01B" }
.icon-kind-arrow-down:before { content: "\E01C" }
.icon-kind-arrow:before { content: "\E01D" }
.icon-license:before { content: "\E01E" }
.icon-lightning:before { content: "\E01F" }
.icon-line:before { content: "\E020" }
.icon-list:before { content: "\E021" }
.icon-lock-open:before { content: "\E022" }
.icon-lock:before { content: "\E023" }
.icon-logout:before { content: "\E024" }
.icon-mail:before { content: "\E025" }
.icon-map-marker:before { content: "\E026" }
.icon-marker:before { content: "\E027" }
.icon-menu:before { content: "\E028" }
.icon-minus:before { content: "\E029" }
.icon-more:before { content: "\E02A" }
.icon-newspaper:before { content: "\E02B" }
.icon-phone:before { content: "\E02C" }
.icon-plus:before { content: "\E02D" }
.icon-print:before { content: "\E02E" }
.icon-read-more:before { content: "\E02F" }
.icon-reset:before { content: "\E030" }
.icon-search:before { content: "\E031" }
.icon-social-facebook:before { content: "\E032" }
.icon-social-google:before { content: "\E033" }
.icon-social-linkedin:before { content: "\E034" }
.icon-social-twitter:before { content: "\E035" }
.icon-spade:before { content: "\E036" }
.icon-star-fill:before { content: "\E037" }
.icon-star-half:before { content: "\E038" }
.icon-star:before { content: "\E039" }
.icon-stats:before { content: "\E03A" }
.icon-status-error:before { content: "\E03B" }
.icon-status-help:before { content: "\E03C" }
.icon-status-info:before { content: "\E03D" }
.icon-status-warning:before { content: "\E03E" }
.icon-tag:before { content: "\E03F" }
.icon-target:before { content: "\E040" }
.icon-time:before { content: "\E041" }
.icon-trash:before { content: "\E042" }
.icon-triangle-down-full:before { content: "\E043" }
.icon-triangle-down:before { content: "\E044" }
.icon-triangle-left:before { content: "\E045" }
.icon-triangle-right:before { content: "\E046" }
.icon-triangle-up:before { content: "\E047" }
.icon-user:before { content: "\E048" }



.icon-arrow-bottom-double-after:after,
.icon-arrow-down-after:after,
.icon-arrow-left-double-after:after,
.icon-arrow-left-after:after,
.icon-arrow-right-bold-after:after,
.icon-arrow-right-double-after:after,
.icon-arrow-right-after:after,
.icon-arrow-top-double-after:after,
.icon-arrow-up-after:after,
.icon-book-after:after,
.icon-bubbles-after:after,
.icon-bullet-after:after,
.icon-calendar-after:after,
.icon-circle-after:after,
.icon-clipboard-after:after,
.icon-close-after:after,
.icon-comment-after:after,
.icon-comments-after:after,
.icon-dashboard-after:after,
.icon-edit-after:after,
.icon-extlink-after:after,
.icon-file-after:after,
.icon-files-after:after,
.icon-grid-after:after,
.icon-handle-after:after,
.icon-heart-after:after,
.icon-help-after:after,
.icon-kind-arrow-down-after:after,
.icon-kind-arrow-after:after,
.icon-license-after:after,
.icon-lightning-after:after,
.icon-line-after:after,
.icon-list-after:after,
.icon-lock-open-after:after,
.icon-lock-after:after,
.icon-logout-after:after,
.icon-mail-after:after,
.icon-map-marker-after:after,
.icon-marker-after:after,
.icon-menu-after:after,
.icon-minus-after:after,
.icon-more-after:after,
.icon-newspaper-after:after,
.icon-phone-after:after,
.icon-plus-after:after,
.icon-print-after:after,
.icon-read-more-after:after,
.icon-reset-after:after,
.icon-search-after:after,
.icon-social-facebook-after:after,
.icon-social-google-after:after,
.icon-social-linkedin-after:after,
.icon-social-twitter-after:after,
.icon-spade-after:after,
.icon-star-fill-after:after,
.icon-star-half-after:after,
.icon-star-after:after,
.icon-stats-after:after,
.icon-status-error-after:after,
.icon-status-help-after:after,
.icon-status-info-after:after,
.icon-status-warning-after:after,
.icon-tag-after:after,
.icon-target-after:after,
.icon-time-after:after,
.icon-trash-after:after,
.icon-triangle-down-full-after:after,
.icon-triangle-down-after:after,
.icon-triangle-left-after:after,
.icon-triangle-right-after:after,
.icon-triangle-up-after:after,
.icon-user-after:after {
    display: inline-block;
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

.icon-arrow-bottom-double-after:after { content: "\E001" }
.icon-arrow-down-after:after { content: "\E002" }
.icon-arrow-left-double-after:after { content: "\E003" }
.icon-arrow-left-after:after { content: "\E004" }
.icon-arrow-right-bold-after:after { content: "\E005" }
.icon-arrow-right-double-after:after { content: "\E006" }
.icon-arrow-right-after:after { content: "\E007" }
.icon-arrow-top-double-after:after { content: "\E008" }
.icon-arrow-up-after:after { content: "\E009" }
.icon-book-after:after { content: "\E00A" }
.icon-bubbles-after:after { content: "\E00B" }
.icon-bullet-after:after { content: "\E00C" }
.icon-calendar-after:after { content: "\E00D" }
.icon-circle-after:after { content: "\E00E" }
.icon-clipboard-after:after { content: "\E00F" }
.icon-close-after:after { content: "\E010" }
.icon-comment-after:after { content: "\E011" }
.icon-comments-after:after { content: "\E012" }
.icon-dashboard-after:after { content: "\E013" }
.icon-edit-after:after { content: "\E014" }
.icon-extlink-after:after { content: "\E015" }
.icon-file-after:after { content: "\E016" }
.icon-files-after:after { content: "\E017" }
.icon-grid-after:after { content: "\E018" }
.icon-handle-after:after { content: "\E019" }
.icon-heart-after:after { content: "\E01A" }
.icon-help-after:after { content: "\E01B" }
.icon-kind-arrow-down-after:after { content: "\E01C" }
.icon-kind-arrow-after:after { content: "\E01D" }
.icon-license-after:after { content: "\E01E" }
.icon-lightning-after:after { content: "\E01F" }
.icon-line-after:after { content: "\E020" }
.icon-list-after:after { content: "\E021" }
.icon-lock-open-after:after { content: "\E022" }
.icon-lock-after:after { content: "\E023" }
.icon-logout-after:after { content: "\E024" }
.icon-mail-after:after { content: "\E025" }
.icon-map-marker-after:after { content: "\E026" }
.icon-marker-after:after { content: "\E027" }
.icon-menu-after:after { content: "\E028" }
.icon-minus-after:after { content: "\E029" }
.icon-more-after:after { content: "\E02A" }
.icon-newspaper-after:after { content: "\E02B" }
.icon-phone-after:after { content: "\E02C" }
.icon-plus-after:after { content: "\E02D" }
.icon-print-after:after { content: "\E02E" }
.icon-read-more-after:after { content: "\E02F" }
.icon-reset-after:after { content: "\E030" }
.icon-search-after:after { content: "\E031" }
.icon-social-facebook-after:after { content: "\E032" }
.icon-social-google-after:after { content: "\E033" }
.icon-social-linkedin-after:after { content: "\E034" }
.icon-social-twitter-after:after { content: "\E035" }
.icon-spade-after:after { content: "\E036" }
.icon-star-fill-after:after { content: "\E037" }
.icon-star-half-after:after { content: "\E038" }
.icon-star-after:after { content: "\E039" }
.icon-stats-after:after { content: "\E03A" }
.icon-status-error-after:after { content: "\E03B" }
.icon-status-help-after:after { content: "\E03C" }
.icon-status-info-after:after { content: "\E03D" }
.icon-status-warning-after:after { content: "\E03E" }
.icon-tag-after:after { content: "\E03F" }
.icon-target-after:after { content: "\E040" }
.icon-time-after:after { content: "\E041" }
.icon-trash-after:after { content: "\E042" }
.icon-triangle-down-full-after:after { content: "\E043" }
.icon-triangle-down-after:after { content: "\E044" }
.icon-triangle-left-after:after { content: "\E045" }
.icon-triangle-right-after:after { content: "\E046" }
.icon-triangle-up-after:after { content: "\E047" }
.icon-user-after:after { content: "\E048" }