// Panels
// --------------------------------------------------

.panel-pane {
  position: relative;
  margin-bottom: $default-block-margin-bottom;
}

.pane-title {

}

.pane-node-title {
  padding-left: 8px;
}