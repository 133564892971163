// Region: Footer
// --------------------------------------------------
//

.region--footer {
  padding-top: $default-block-margin-bottom;

  section {
    width: 100%;
    float: left;
    margin-bottom: 2rem;
  }

  .node.view-mode-full {
    margin: 0;
  }

  // Footer menu en overlegplatformen layout
  .menu-footer-block, .block-overlegplatform {
    @extend .col;
    @extend .col-lg-6;
    padding-left: 0;
  }

  // menu footer
  .menu-footer-block {
    border-bottom: 1px solid $color-white;
    padding-bottom: 2rem;
    * {
      color: $color-white;
    }

    @include bp(lg) {
      border-bottom: none;
      border-right: 1px solid $color-white;
    }

    ul.menu {
      column-count: 3;

      // Prevent items to split over 2 cols
      li {
        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid; /* Theoretically FF 20+ */
        break-inside: avoid-column; /* IE 11 */
      }

    }

  }

  // Overlegplatformen block footer
  .block-overlegplatform {
    float: left;
    padding-left: 2rem;

    * {
      color: $color-white;
    }

    .message_footer-image {
      @extend .col;
      @extend .col-xs-3;
    }
    .message_footer-massage {
      @extend .col;
      @extend .col-xs-8;
      @extend .col-xs-offset-3;
    }

  }

  // organisation logo's footer block
  .logo-footer-block {
    margin: 0;

    .content {
      @extend .extendfull;
      padding: 1rem 0;
      background-color: $color-white;
    }

    .logo-wrapper {
      margin: 0;
      justify-content: space-between;

      a {
        @extend .col;
        @extend .col-xs-12;
        @extend .col-sm-6;
        @extend .col-lg-3;
        margin-bottom: 1rem;
        border: none;
        text-align: center;
        @include bp(sm) {
          text-align: left;
        }
        @include bp(lg) {
          text-align: center;
        }

        //img {
        //  max-height: 4rem;
        //}
      }
    }

  }

  // vlaamse overheid info bar footer
  .flemish-footer-block {
    margin: 0;

    .content {
      @extend .extendfull;
      background-color: $color-background-dark;
      color: $color-white;
      p {
        margin: 0;
        padding: 1rem 0;
      }
      a {
        color: $color-white;
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

}