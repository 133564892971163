
// Overlays
// --------------------------------------------------

// Overlay background

.overlay-background,
.ui-widget-overlay {
  position: fixed;
  opacity: 1 !important;
  z-index: 200;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-black, 0.8) !important;
}

// Overlay dialog

.ui-dialog {
	z-index: 201;
	max-width: 100% !important;
	background: $color-white;
	iframe {
		border: 0 none;	
	}
}
.ui-dialog-titlebar {
	padding: 1rem;
	min-height: 4rem;
	button {
		margin-bottom: 0;
	}
	.ui-dialog-titlebar-close {
		@extend .button-close !optional;
		float: right;
	}
}
