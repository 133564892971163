.not-front .view-nieuwsberichten {

  .view-content {

    @extend .clearfix;

    @extend .col;
    @extend .col-xl-10;
    @extend .col-xl-offset-1;

    // Center line
    &:before {
      content: " ";
      position: absolute;
      height: 100%;
      width: 3px;
      left: 0;

      background: rgba(255, 255, 255, 0);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 10%, rgba(10, 113, 140, 1) 90%, rgba(10, 113, 140, 0) 100%);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 10%, rgba(10, 113, 140, 1) 90%, rgba(10, 113, 140, 0) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(10%, rgba(10, 113, 140, 1)), color-stop(90%, rgba(10, 113, 140, 1)), color-stop(100%, rgba(10, 113, 140, 0)));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 10%, rgba(10, 113, 140, 1) 90%, rgba(10, 113, 140, 0) 100%);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 10%, rgba(10, 113, 140, 1) 90%, rgba(10, 113, 140, 0) 100%);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 10%, rgba(10, 113, 140, 1) 90%, rgba(10, 113, 140, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#0a718c', GradientType=0);
    }

    @include bp(md) {
      &:before {
        left: 50%;
      }
    }

    .nieuws-timeline.column {
      width: 50%;
      float: left;
      position: relative;

      &:last-child {
        padding-top: 13rem;
      }

      &.column_2of2 {
        .field-name-post-date {
          float: left;
          text-align: left;
        }
      }

    }

    .views-row {
      margin-left: 1rem;

      @include icon('bullet') {
        @include font-size(20, 0);
        position: absolute;
        color: $color-primary;
        font-weight: 900;
        border: 4px solid $color-white;
        border-radius: 50%;
      }

      &.views-row-first {
        padding-top: 6rem;
      }
      &.views-row-odd {
        &:before {
          left: -12px;
        }
      }
      &.views-row-even {
        &:before {
          left: -13px;
        }

      }

      @include bp(md) {
        margin-left: 0rem;

        &.views-row-odd {
          margin-right: 2rem;
          margin-bottom: 10rem;
          &:before {
            right: -15px;
            left: auto;
          }
        }
        &.views-row-even {
          margin-left: 2rem;
          margin-bottom: 7rem;
          padding-top: 3rem;
        }
        
        .field-name-post-date {
          float: right;
          text-align: right;
          padding-bottom: 0.5rem;
        }
        .field-name-title,
        .field--body,
        .field--afbeelding {
          margin-left: 2rem;
        }

      }

    }

  }

  ul.pager-show-more,
  ul.pager-load-more {
    @extend .col-md-offset-6;
    padding-left: 2rem !important;

    @include bp(md) {
      top: -4rem;
      &.pager-show-more-next {
        padding-left: 2rem !important;
      }
    }

  }

}

//.simple_timeline {
//
//  &:first-child .timeline {
//    padding-top: 8rem;
//
//    li:first-child {
//      padding-top: 5rem;
//    }
//
//  }
//
//  .node-teaser {
//    padding-left: 1rem;
//  }
//
//  //
//  // Views fields
//  //
//  .field-name-post-date {
//    @include font-size(20);
//    color: $color-muted;
//    padding-bottom: 1rem;
//  }
//
//  @include bp(lg) {
//    li:nth-child(even) {
//      .field-name-title,
//      .field--body,
//      .field--node-link {
//        padding-left: 2rem;
//      }
//    }
//  }
//
//  .field-name-title h2 {
//    @include font-size(22);
//  }
//
//  .field--body {
//
//  }
//
//  .field--node-link {
//
//  }
//
//}