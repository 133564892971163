// Node
// --------------------------------------------------

.node {
  position: relative;
}

.ds-onclick {
  cursor: pointer;
  transition: color $default-transition;
  &:hover {
    color: $color-link;
  }
}

// Labels
// --------------------------------------------------

label.field-label,
div.field-label,
span.field-label {
  @extend label;
  display: inline-block;
  margin-right: 0.5rem;
}

.label-inline {
  @extend label;
  label.field-label,
  div.field-label,
  span.field-label,
  div.field-content {
    display: inline-block;
  }
}

// Fields
// --------------------------------------------------

// Show all images button.
.show-more-images.button {
  width: 100%;
  float: left;
  //  margin: 1rem 0;

  @include bp(sm) {
    //@extend .button-primary;
    color: $color-white;
    background-color: $color-primary;
    border: solid 1px $color-primary;
    
    &:hover,
    &:focus {
      color: $color-white;
      background-color: lighten($color-primary, 10%);
      border-color: lighten($color-primary, 10%);
    }
    
    position: absolute;
    bottom: 0;
    margin: 0;
    width: 100%;
  }
}

.field--categorie-habitattype {
  margin-bottom: 1rem;
}

.field-label {
  margin-bottom: 1rem;
}

.field--title {
  > span {
    display: inline-block;
    font-size: 0.5em;
    line-height: 1em;
    color: $color-muted;
  }
}

.field--date {
  @include font-size(14);
  color: $color-muted;
  @include icon('time') {
    margin-right: 0.5rem;
  }
}

.field--body {
  a {
    @extend .inline-link;
  }
}

.field--node-link {
  a {
    @extend .read-more;
  }
}

.field--bijlagen {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: inline-block;
  .field-item {
    position: relative;
  }
  a {
    vertical-align: super;
    position: absolute;
    top: 1rem;
  }
  img {
    width: 2rem;
  }
}

.field--afbeeldingen {
  .field-item {
    float: left;
    display: block;
    position: relative;
    img {
      display: block;
    }
    @include bp(sm, max) {
      margin: 0 !important;
      padding: 0 !important;
      width: 50%;
    }
  }
  // theming for the 'show all images' button, added by js.
}

.pane-node-field-contactpersonen {
  @extend .clearfix;
  padding-top: 3rem;
  margin-top: 5rem;
  margin-bottom: 1rem;

  &:before {
    content: "";
    height: 2px;
    display: block;
    width: 100%;
    position: absolute;
    top: -2px;

    background: rgba(255, 255, 255, 0);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    // Fallbacks for all browsers
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(23%, rgba(10, 113, 140, 1)), color-stop(80%, rgba(10, 113, 140, 1)), color-stop(100%, rgba(10, 113, 140, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(10, 113, 140, 1) 23%, rgba(10, 113, 140, 1) 80%, rgba(10, 113, 140, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#0a718c', GradientType=1);
  }

  label.field-label {
    font-family: $default-font;
  }

  .pane-content {

    .field--contactpersonen > .field-label {
      @extend .col;
      @extend .col-sm-8;
      @extend .col-sm-offset-3;
      @include font-size(22);
      font-weight: 200;
    }

    .field-item {
      margin-bottom: 1rem;
    }

    .field--contactperson-image {
      @extend .col;
      @extend .col-sm-3;
      float: left;
      text-align: center;
    }
    .group-general.field-group-div {
      @extend .col;
      @extend .col-sm-8;
      float: left;
    }
    .field--naam {
      font-weight: 800;
      float: left;
      display: inline-block;
    }
    .field--naam-dienst {
      display: inline-block;
      padding-left: 1rem;
      position: relative;
      &:before {
        content: " - ";
        position: absolute;
        left: 0;
        padding: 0 0.4rem;
      }
    }
  }

}

// Projects CT, call to action info link.
.field--project-cta a {
  @extend .button;
}

.field--lange-benaming {
  font-weight: 800;
}

// Field groups
// --------------------------------------------------

// View modes
// --------------------------------------------------

// List

.node.view-mode-list {

}

// Search result

.node.view-mode-search_result {
  //	margin-bottom: $default-block-margin-bottom;
}

/*--< TEASERS >--*/

.node-fotoalbum.node-teaser {
  .field-item {
    display: none;
  }
  .field-item:first-child {
    display: block;
  }
}

.node.view-mode-teaser {
  margin-bottom: $default-block-margin-bottom;
}

//
// Planten & dieren teaser on masonry view
//
.node-habitattype.node-teaser,
.node-soort.node-teaser {
  display: block;
  margin-bottom: 1rem;

  .field--title {
    * {
      @include font-size(20);
      color: $color-black;
      margin-bottom: 0;
    }
  }

  .field--afbeeldingen {
    display: block;
    img {
      display: block;
    }
  }

  &:hover {
    .field--title {
      background-color: rgba($color-primary, 0.9) !important;
    }
  }

}

// Provide some sort of fallback while the masonry is being loaded.
// So in the time between no masonry, and initialized masonry it still looks decent.
.masonry-processed .node-habitattype.node-teaser,
.masonry-processed .node-soort.node-teaser {
  .field--title {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    background-color: rgba($color-secondary, 0.8);
    * {
      @include font-size(20);
      color: $color-white;
      margin-bottom: 0;
    }
  }
}

//
// project teaser
//
.node-project.node-teaser {
  margin-bottom: 1rem;
  background-color: $color-grey;
  transition: $default-transition;

  .field--title, .field--title h2 {
    @include font-size(24);
    color: $color-primary;
    transition: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color-primary;
    color: $color-white;
    .field--title, .field--title h2 {
      color: $color-white !important;
      transition: none;
    }
    .field--node-link a {
      padding-top: 1rem;
      color: $color-white !important;
      &:after {
        color: $color-white;
      }
    }
  }

  .field--node-link {
    margin-top: 1rem;
    a:after {
      color: $color-primary;
    }
  }

}

//
// publicatie teaser
//
.node-publicatie.node-teaser {
  margin-bottom: 1rem;

  .field-group-accordion-wrapper,
  .field--bijlagen,
  .field--links {
    float: left;
    @extend .col;
    @extend .col-md-6;
    padding: 0;
  }

  .field-group-accordion-wrapper {
    border: 2px solid $color-primary;

    h3 {
      background-color: $color-primary;
      padding: 0.5rem;
      margin: 0;
      * {
        color: $color-white;
      }
      &:hover {
        cursor: pointer;
      }

      &.field-group-format-toggler {
        @include icon('arrow-down') {
          padding-right: 0.5rem;
          color: $color-white;
        }
      }
      &.ui-state-active {
        @include icon('arrow-up');
      }
    }
  }

  .field-item {
    position: relative;
  }

  .field--teaser {
    padding: 1rem;
  }

  .field--links {
    padding-top: 1rem;
    @include icon('extlink') {
      color: $color-muted;
      padding: 0 4px 0 4px;
    }
  }

  @include bp(md) {
    .field--links,
    .field--bijlagen {
      padding-left: 0.9rem;
      a {
        vertical-align: super;
        position: absolute;
        top: 1rem;
      }
    }
    .field--bijlagen {
      margin-top: 0;
      img {
        padding-top: 0.5rem;
      }
      a {
        padding-left: 0.5rem;
      }
    }

  }

}

//
// Sticky 'over pagina' teasers
//
.node-over-pagina.node-sticky.node-teaser {
  background-color: $color-grey;
  padding: 2rem;
  margin-bottom: 4rem;

  // TODO: change into leaf icon.
  @include icon('map-marker') {
    @include font-size(40, 0);
    position: absolute;
    top: -3rem;
    left: 42%;
    color: $color-white;
    background-color: $color-secondary;
    border-radius: 50%;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.3);;
    padding: 1.2rem 0.8rem 0.8rem 1.2rem;
  }

  .field--title h2 {
    @include font-size(24);
    color: $color-primary;
  }

  &:hover {
    background-color: $color-primary;
    //    transition: background-color $default-transition;
    transition: $default-transition;
    color: $color-white;
    .field--title h2 {
      color: $color-white;
    }
    a {
      color: $color-white;
      border-color: $color-white;
    }
  }
  a:hover {
    border-bottom: none;
  }

}

//
// Nieuws teaser
//
.node-nieuwsbericht.node-teaser {
  margin-bottom: 2rem;
  padding-top: 0.3rem;

  .field-name-title * {
    @include font-size(20);
    padding-top: 0.5rem;
  }

  .field--body p {
    margin-bottom: 0;
  }

  .field--afbeelding {
    margin: 0 1rem 0.5rem 0;
    float: left;
  }

  .field-name-post-date {
    color: $color-muted;
    width: 100%;
  }

  .field--node-link {
    padding-top: 0.3rem;
  }

  @include bp(md) {
    margin-bottom: 0;
    .field--node-link {
      text-align: center;
    }
  }

}

// Teaser Large

.node.view-mode-teaser_large {
  margin-bottom: $default-block-margin-bottom;
}

// Full

.node.view-mode-full {
  margin-bottom: $default-block-margin-bottom;
}

.node-nieuwsbericht.view-mode-full {
  .field--datum {
    @include font-size(20);
    color: $color-muted;
    font-style: italic;
    padding-bottom: 1rem;
  }
  .field--afbeelding {
    margin: 0 1rem 0.5rem 0;
    float: left;
  }
}

// Frontpage homepage-block teaser view mode
.front .node-homepage-block {
  &.node-teaser {
    position: relative;
    padding: 1rem;
    color: $color-white;
    box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.4);

    .field--title h2 {
      @include font-size(22);
    }

    .ds-center {
      margin-bottom: 2.5rem;
    }

    .ds-footer {
      position: absolute;
      //      right: 41%;
      bottom: 1rem;

      a {
        color: $color-white;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

  }
}

.node-over-pagina.view-mode-full {

  .group-number-wrapper.field-group-div {
    background-color: $color-grey;
    padding: 1rem 1rem 0.8rem 1rem;
  }
  .field--cijfer {
    display: inline-block;
    padding: 0.3rem 0.8rem;
    margin-bottom: 0.2rem;
    font-weight: 800;
    color: $color-white;
    background-color: $color-secondary;
    @include font-size(18);
  }
  .field--tekst-bij-cijfer {
    padding: 0.1rem 0 0 0.5rem;
    @include font-size(18, 33);
    font-weight: 400;
  }
  .field--body {
    float: left;
    margin-top: 2rem;
  }
  .field--afbeelding {
    margin: 1rem 0;
  }

}

.node-habitattype.view-mode-full {

  .photoswipe-gallery {
    @extend .clearfix;
    display: block;
    width: 100%;
    margin-bottom: 2rem;

    .field-item {
      float: left;
      margin-right: 1rem;
      display: none;

      width: 100%;
      @include bp(sm) {
        width: 50%;
      }
      @include bp(md) {
        width: 45%;
      }
      @include bp(lg) {
        width: auto;
      }

      @include bp(sm, max) {
        &:nth-child(-n+1) {
          display: block;
        }
      }
      @include bp(sm) {
        &:nth-child(-n+2) {
          display: block;
        }
      }

    }

    .field--afbeeldingen {
      @include bp(sm) {
        float: left;
      }

      a, img {
        display: block;
        margin: auto auto;
        text-align: center;
      }
    }

  }
}

// Search result view mode

.view-mode-search_result {
  .field--title {
    //    float: left;
  }
  .field--type-label {
    @include font-size(20);
    margin-bottom: 1rem;
    color: $color-muted;
  }
  .field--afbeeldingen {
    float: left;
    margin: 0 1rem 1rem 0;
  }
}