//
// Gebied detail
//
.node-type-gebied {

  // Tabs & Content layout.
  @include bp(md) {
    .vertical-tabs-list {
      @include col();
      @include cols(2);
      padding: 0;
      float: left;
      display: block;
    }
    .vertical-tabs-panes {
      @include clearfix;
      @include col;
      @include cols(10);
      float: left;
      min-width: auto;
      display: block;
      margin-top: 0;
    }
  }

  .vertical-tabs-panes {
    padding: 0;

    .vertical-tabs-pane {
      padding: 0;
    }

  }

  fieldset.vertical-tabs-pane {
    padding-top: 0;
    width: 100%;
    min-width: auto;
    border: none;
    @include bp(md) {
      padding-left: 2rem !important;
    }

    legend {
      margin-bottom: 1rem;
      .fieldset-legend {
        @extend h2;
      }
    }

  }

  .form-type-openlayers {

    form, .openlayers-container {
      @extend .row;
    }
  }

  // Layout for the gebieden detail algemene info panes.
  .panel-pane {
    margin-bottom: 1rem;
  }

  .pane-node-field-lange-benaming,
  .pane-node-field-oppervlakte-dec,
  .pane-natura-custom-blocks-natura-area-general-info-extra,
  .pane-openlayers-map.pane-map-gebied-detail-small,
  .pane-node-field-fotoalbum {
    @extend .col;
  }

  .pane-node-field-lange-benaming,
  .pane-node-field-oppervlakte-dec,
  .pane-natura-custom-blocks-natura-area-general-info-extra {
    @extend .col-md-3;
    float: left;
    clear: both;
    display: block;
    margin-bottom: 1rem;
  }

  .pane-openlayers-map.pane-map-gebied-detail-small,
  .pane-node-field-fotoalbum {
    @extend .col-xs-6;
    @extend .col-md-4;
    @include bp(md) {
      position: absolute;
    }
    float: left;
    display: block;
    .field--afbeeldingen .field-item {
      width: 100%;
    }
  }

  .pane-openlayers-map.pane-map-gebied-detail-small {
    @extend .col-md-offset-4;
    overflow: hidden;

    .map-before-wrapper {
      display: none;
    }

    .pane-content {
      position: relative;
    }
    .view-map-detail {
      position: absolute;
      padding: 0.5rem;
      background-color: rgba($color-secondary, 0.9);
      color: $color-white;
      width: 100%;
      text-align: left;
      bottom: 0;
      z-index: 1200;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .pane-node-field-fotoalbum {
    @extend .col-md-offset-8;
    .field-item {
      display: none;
      &.first {
        display: block;
        .show-more-images {
          position: absolute;
          padding: 0.5rem;
          background-color: rgba($color-secondary, 0.8);
          color: $color-white;
          width: 100%;
          text-align: left;
          bottom: 0;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .pane-node-field-algemene-beschrijving {
    @include bp(md) {
      margin-top: 6rem;
    }
  }

  .pane-node-field-algemene-beschrijving,
  .pane-node-field-identificatie,
  .pane-node-field-ls-beschrijving,
  .pane-node-field-actief-in-gebied,
  .pane-node-field-postcodes-gemeenten {
    @extend .col;
    @extend .col-md-12;
    @extend .clearfix;
    float: left;
    width: 100%;
  }

  .openlayers-container-map-map-gebied-detail-small,
  .openlayers-map-map-gebied-detail-small {
    width: auto !important;
  }

}

//
// Gebied overzicht
//
.pane-openlayers-map.pane-map-gebied-overview {

  .openlayers-container {
    display: none;
    @include bp(md) {
      display: block;
    }
  }

  .map-before-wrapper {
    width: 100%;
    display: block;
    margin-bottom: 1.45rem;
  }

  .map-title {
    display: none;
    @include bp(md) {
      display: block;
    }
    float: left;
    font-family: $default-font;
    @include font-size(24);
    font-weight: 300;
    h3 {
      margin-bottom: 0;
    }
  }

  .map-search-filter-value {
    display: block;
    float: right;
//    margin-top: 0.5rem;
    @include font-size(18);
  }

}

// exposed search field form
.gebieden-overzicht-exposed-search-form {
  @extend .col;
  @extend .col-md-8;
  @extend .col-lg-7;
  @extend .col-md-offset-2;
  @extend .col-lg-offset-3;

  .views-exposed-widget {
    @extend .col;
    @extend .col-sm-8;
    @extend .col-md-9;
    @extend .col-lg-9;
    @extend .col-xl-8;
  }
  .views-exposed-options {
    @extend .col;
    @extend .col-sm-3;
    @extend .col-md-3;
    @extend .col-lg-3;
    @extend .col-xl-3;

    @include bp(xs, max) {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .form-type-geofield-proximity, .geofield-proximity-field-wrapper {
    position: relative;
  }
  span.locate-me-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    &:hover {
      cursor: pointer;
    }
    a:before {
      @include font-size(24, 0);
      margin-top: 5px;
    }
  }

  .form-item-location-origin {
//    @include icon('target', after) {
//      @include font-size(24, 0);
//      color: $color-primary;
//      position: absolute;
//      right: 0px;
//      top: 7px
//    }
  }

  input.geofield-proximity-origin {
    border: none;
    border-bottom: 2px solid $color-primary;
    background: transparent;
    margin-right: 1rem;
    padding-right: 2rem;
    width: 100%;

    &:focus, &:active {
      outline: none;
      box-shadow: none;
      //        border-bottom: 2px solid $color-secondary;
    }
  }

  button {
    @extend .button-primary;
  }
}

.gebieden-overzicht {

  .row-content {
    height: auto;
    overflow-y: visible;
    overflow-x: hidden;
    //    min-height: 60rem;
  }

  // Kaart container
  .openlayers-map-container {

  }

  // view pane wrapper
  .pane-openlayers-source-gebieden-gebieden-overview-sidebar {
    margin-bottom: 0;
  }

  // button wrapper
  .gebieden-overlay-toggle-container {
    position: relative;
    background-color: $color-white;
  }

  button.gebieden-overlay-toggle {
    transition: none !important;

    @include icon('arrow-left') {
      @include font-size(14, 0);
    }
    width: 100%; // TODO: replace this with
    z-index: 1110;

    &:before, &:after {
      position: absolute;
      @include font-size(14, 0);
      top: 0.7rem;
    }

    &:after {
      content: " ";
      right: 0.5rem;
    }
    &:before {
      left: 0.5rem;
    }

    &.open {
      &:before {
        content: " ";
      }
      @include icon('arrow-right', after) {
        @include font-size(14, 0);
      }
    }

    &.is_stuck + .view-openlayers-source-gebieden {
      margin-top: 3rem;
    }

  }

}

.view-openlayers-source-gebieden {
  position: relative;
  background-color: $color-grey;
  z-index: 1100;

  .sub-title {
    padding: 1rem 1.5rem;
    border-bottom: 2px solid $color-white;

    h2 {
      margin-bottom: 0;
    }
  }

  .view-content {
    background-color: $color-white;
    //    @include bp(md) {
    //      overflow-y: scroll;
    //      height: 701px
    //    }
  }

  h3 {
    padding: 1rem 1.5rem;
    background-color: $color-grey;
    margin-bottom: 2px;
    font-family: $secondary-font;
    @include font-size(20);

    &.active, &:hover {
      background-color: $color-secondary;
      color: $color-white;
      cursor: pointer;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: " ";
      display: block;
      margin-top: -75px;
      height: 75px;
      visibility: hidden;
    }

    .proximity-title {
      @include font-size(14);
      float: none;
      margin-top: 0.4rem;
      padding-left: 0.5rem;

      &:before {
        content: "-";
        padding: 0.5rem 0.5rem 0.5rem 0rem;
      }
    }

  }

  .views-row {
    display: none;
    background-color: $color-grey;
    position: absolute;
    padding: 1rem;

    z-index: 3;
    top: 0;
    left: 100% !important;
    min-height: 100%;
    width: 250%;

    &.open {
      display: block;
    }
    &.bottom {
      display: block;
      right: 0px !important;
      left: 100% !important;
    }

    .field-fotoalbum {
      margin-bottom: 1rem;
    }
    .read-more-link {
      float: right;
      @include icon('arrow-right', after) {
        @include font-size(14);
      }
    }

    .path, .proximity {
      display: none;
    }

  }

  // custom button that links to the gebied detail.
  .gebieden-detail-toggle {
    padding: 0.5rem;
    a.button {
      width: 100%;
      margin: 0;
    }
  }

}

// Clear current selection button.
.gebieden-clear-container {
  border-top: 1px solid $color-grey;
  background-color: $color-white;
}

.clear-selection-button {
  width: 100%;
  margin-top: 1rem;
}

// Kaart popup
.olPopup {
  border-radius: 0;
  @include font-size(16);

  &:before, &:after {
    //    left: auto;
    //    right: 25px;
  }

  a {
    @extend .button;
    background-color: $color-white;
    margin-top: 0.5rem;
  }
}

// Base layer switcher
.olControlLayerSwitcher .layersDiv {
  background-color: $color-primary !important;
}

.olButton {
  width: 0px;
}

.olButton:hover {
  cursor: pointer;
}