// Region: Header
//--------------------------

.region--header {
  z-index: 50;
  background-color: rgba($color-primary, 1);
  display: block;
  width: 100%;
  min-height: 4rem;
  position: absolute;

  @include bp(lg) {
    background-color: rgba($color-primary, 0.8);
  }
}

// Branding

.branding {
  float: left;
  width: 45%;
  @include bp(md) {
    margin-right: 2rem;
    width: auto;
  }
  @include bp(xl) {
    margin-right: 6rem;
  }
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  .site-name,
  .site-slogan {
    display: none;
  }

  .site-logo, .site-logo img {
    display: block;
  }

}

// Blocks

.region--header {
  .block-menu {
    float: left;
  }
}

// Header logo's
.header-logo-wrapper {
  @extend .col;
  position: absolute;
  display: none;

  @include bp(md) {
    display: block;

    .container {
      margin-top: 4rem;
    }

    .header-logo-1,
    .header-logo-2 {
      width: 160px;
    }

    .header-logo-1 {
      width: 240px;
      margin-right: 3rem;
      padding-bottom: 1rem;
    }
    .header-logo-2 {
      width: 130px;
      padding-top: 3rem;
    }

  }

}