
// Classes
// --------------------------------------------------

.hidden {
  display: none !important;
}
.visible {
  dispay: block !important;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
  width: auto;
}

.invisible {
  visibility: hidden;
}

// Floats

.float-left {
  float: left;
}
.float-right {
  float: right;
}

// Hidden Elements

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  @include reset;
}

// Show / hide on mobile

.hide-mobile {
  @include bp(sm, max) { display: none; }
}
.show-mobile {
  @include bp(md, min) { display: none; }
}

// Extends

.full-width-mobile {
  margin-left: -$gutter;
  margin-right: -$gutter;
  @include bp(md) {
    margin-left: 0;
    margin-right: 0;
  }
}

.extendfull {
  @include bp(sm, max) {
    padding-left:  300px !important;
    margin-left:  -300px !important;
    padding-right: 300px !important;
    margin-right: -300px !important;
  }
  @include bp(md) {
    padding-left:  3000px !important;
    margin-left:  -3000px !important;
    padding-right: 3000px !important;
    margin-right: -3000px !important;
  }
}
.extendleft {
  @include bp(sm, max) {
    padding-left:  300px !important;
    margin-left:  -300px !important;
    padding-right: 300px !important;
    margin-right: -300px !important;
  }
  @include bp(md) {
    padding-left:  3000px !important;
    margin-left:  -3000px !important;
    padding-right: 0;
    margin-right: 0;
  }
}
.extendright {
  @include bp(sm, max) {
    padding-left:  300px !important;
    margin-left:  -300px !important;
    padding-right: 300px !important;
    margin-right: -300px !important;
  }
  @include bp(md) {
    padding-left:  0;
    margin-left:  0;
    padding-right: 3000px !important;
    margin-right: -3000px !important;
  }
}

// Colors

.color-background {
  color: $color-background;
}
.color-primary {
  color: $color-primary;
}
.color-secondary {
  color: $color-secondary;
}
.color-black {
  color: $color-black;
}
.color-grey {
  color: $color-grey;
}
.color-white {
  color: $color-white;
}
.color-text {
  color: $color-text;
}
.color-muted {
  color: $color-muted;
}
.color-link {
  color: $color-link;
}
.color-status {
  color: $color-status;
}
.color-warning {
  color: $color-warning;
}
.color-error {
  color: $color-error;
}

// Background colors

.background-background {
  background: $color-background;
}
.background-primary {
  background: $color-primary;
}
.background-secondary {
  background: $color-secondary;
}
.background-black {
  background: $color-black;
}
.background-grey {
  background: $color-grey;
}
.background-white {
  background: $color-white;
}
.background-text {
  background: $color-text;
}
.background-muted {
  background: $color-muted;
}
.background-link {
  background: $color-link;
}
.background-status {
  background: $color-status;
}
.background-warning {
  background: $color-warning;
}
.background-error {
  background: $color-error;
}

// Border

.default-border {
  border: $default-border;
}
.default-border-top {
  border-top: $default-border;
}
.default-border-right {
  border-right: $default-border;
}
.default-border-bottom {
  border-bottom: $default-border;
}
.default-border-left {
  border-left: $default-border;
}

// Margin
.margin-middle {
  margin-right: auto;
  margin-left: auto;
}
.no-margin {
  margin: 0;
}
.no-margin-vertical {
  margin-top: 0;
  margin-bottom: 0;
}
.no-margin-horizontal {
  margin-left: 0;
  margin-right: 0;
}
.no-margin-top {
  margin-top: 0;
}
.no-margin-right {
  margin-right: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}
.no-margin-left {
  margin-left: 0;
}

// Block margin

.default-block-margin-top {
  margin-top: $default-block-margin-bottom; 
}

.default-block-margin-bottom {
  margin-bottom: $default-block-margin-bottom; 
}

// Padding

.no-padding {
  padding: 0;
}
.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0;
}
.no-padding-top {
  padding-top: 0;
}
.no-padding-right {
  padding-right: 0;
}
.no-padding-bottom {
  padding-bottom: 0;
}
.no-padding-left {
  padding-left: 0;
}

// Text

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

